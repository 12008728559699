import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

export const submitMainResponseAction = createAsyncThunk(
  "submitMainResponse/questionaire",
  async (payload, { rejectWithValue }) => {
    try {
      const mainResponse = await SideEffect.submitMainResponse(payload);
      return mainResponse;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const submitMainResponse = createSlice({
  name: "submitMainResponse",
  initialState: {
    mainResponseNotify: "",
  },
  reducers: {
    mainResponseNotifyAction: (state, { payload }) => {
      state.mainResponseNotify = payload;
    },
  },
  extraReducers: {
    [submitMainResponseAction.fulfilled]: (state, action) => {
      console.log('submitMainResponseAction', action)
      state.mainResponseNotify = "success";
    },
    [submitMainResponseAction.pending]: (state, { payload }) => {
      console.log('submitMainResponseAction', payload)
      state.mainResponseNotify = "loading";
    },
    [submitMainResponseAction.rejected]: (state, { error }) => {
      console.log('submitMainResponseAction', error)
      state.mainResponseNotify = "error";
    },
  },
});

export const { mainResponseNotifyAction } = submitMainResponse.actions;
export default submitMainResponse.reducer;
