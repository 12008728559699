import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

export const fetchSurveyHistoryAction = createAsyncThunk(
  "surveyhistory/history",
  async (payload, { rejectWithValue }) => {
    try {
      const surveyHistory = await SideEffect.fetchParticipantSurveyHistory(
        payload
      );
      return surveyHistory.data.data.surveys;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const fetchSurveyHistory = createSlice({
  name: "surveyhistory",
  initialState: {
    surveyHistory: [],
    error: "",
    loading: "",
  },
  reducers: {},
  extraReducers: {
    [fetchSurveyHistoryAction.fulfilled]: (state, action) => {
      state.surveyHistory = action.payload;
    },
    [fetchSurveyHistoryAction.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [fetchSurveyHistoryAction.rejected]: (state, { error }) => {
      state.loading = "error";
      state.error = error;
    },
  },
});

export default fetchSurveyHistory.reducer;
