import React from 'react';
import { FaPen } from "react-icons/fa";
import styled from "styled-components";
import { white, lightgreen2 } from "../globalAssets/theme";

import { device } from "../globalAssets/mediaQueries";

export const IPen = ({ onClick }) => {
  return <StyledPen onClick={onClick} />;
};

const StyledPen = styled(FaPen)`
  margin-top: 7px;
  right: 5px;
  background: ${lightgreen2};
  color: ${white};
  font-size: 14px;
  z-index: 500;
  position: absolute;
  @media ${device.laptop} {
    display: none;
  }
`;
