import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  CompoenentContainer,
  MobileHeader,
  LaptopHeader,
  IBackArrow,
  IAdd,
  AddAccount,
  LoggerButton,
  Text1,
} from '../../globalComponent/Index';
import { Tag } from 'antd';
import { showModal } from '../../redux-toolkit/AddAccountSlice';
import {
  DivContainer,
  RedeemableDiv,
  LifetimeDiv,
  DivTitle,
  StatVal,
  TextSmallDiv,
  TextSmall,
} from './Style';
import { Row, Col, Typography, Space } from 'antd';
import { device } from '../../globalAssets/mediaQueries';
import { BuyAirtimeModal } from '../../globalComponent/BuyAirtime';
import { BankTransferModal } from  '../../globalComponent/TransferToBank'
import { toggleModal } from '../../redux-toolkit/transactionSlice';

const TransctionBoard = () => {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const visible = useSelector((state) => state.addAccount.visible);
  const dispatcher = useDispatch();
  const color = true;

  console.log({ visible });

  const isAccountAvailable = () => {
    return (
      userInfo?.bank_name && userInfo?.account_name && userInfo?.account_num
    );
  };
  return (
    <CompoenentContainer>
      <IBackArrow />
      <div onClick={() => dispatcher(showModal())}>
        <IAdd />
      </div>

      <MobileHeader changeColor={color} title='Rewards' />
      <LaptopHeader title='Rewards' />
      <div
        style={{
          padding: '20px 20px',
          margin: '30px auto',
          textAlign: 'center',
        }}
      >
        <DivContainer>{/* <DivTitle>Rewards</DivTitle> */}</DivContainer>
        <Row justify='center'>
          <Col xs={24} sm={24} md={12}>
            {isAccountAvailable() ? (
              <>
                <Typography.Title level={5}>
                  Your withdrawal will be credited to this account
                </Typography.Title>
                <BankInfo title='Bank Name' value={userInfo?.bank_name} />
                <BankInfo title='Account Name' value={userInfo?.account_name} />
                <BankInfo
                  title='Account Number'
                  value={userInfo?.account_num}
                />
                <ShowModalDiv onClick={() => dispatcher(showModal())}>
                  <Tag color='green'>Update Account Details</Tag>
                </ShowModalDiv>
                <Row justify='center'>
                  <Col xs={24} sm={24} md={24}>
                    <LoggerButton
                      size={'large'}
                      text='Buy Airtime'
                      onClick={() => dispatcher(toggleModal('buyAirtime'))}
                    />
                    <br />
                    <LoggerButton size='large' text='Transfer to Bank'  onClick={() => dispatcher(toggleModal('bankTransfer'))}/>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <ShowModalDiv onClick={() => dispatcher(showModal())}>
                  <Tag color='red'>Add Account Details</Tag>
                </ShowModalDiv>
              </>
            )}
            {/* <LoggerButton size={"large"} text="Request Withdrawal" /> */}
          </Col>
        </Row>

        <TextSmallDiv>
          Please note that you must earn at least 1000 naira before your request
          can be processed
        </TextSmallDiv>
      </div>
      <AddAccount />
      <BuyAirtimeModal />
      <BankTransferModal/>
    </CompoenentContainer>
  );
};

const BankInfo = (props) => {
  return (
    <Typography.Paragraph>
      {props.title}: <Typography.Text strong>{props.value} </Typography.Text>
    </Typography.Paragraph>
  );
};

const ShowModalDiv = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    cursor: pointer;
  }
`;
export default TransctionBoard;
