import React from "react";
import styled from "styled-components";
import { ILocation } from "./ILocation";
import { Select } from "antd";
import { activeShadow, inActiveShadow } from "../globalAssets/theme";
import { FaLocationArrow } from "react-icons/fa";

export const DropDown = ({ onChange, values }) => {
  return (
    <DropdownContainer>
      {/* <FaLocationArrow style={{ fontSize: "10px", marginLeft: "10px" }} /> */}
      <ILocation />
      <Dropdown onChange={onChange}>
        <Option value="" />
        {values.map((value) => (
          <Option key={value.key} value={value.text}>
            {value.text}
          </Option>
        ))}
      </Dropdown>
    </DropdownContainer>
  );
};

// const bankArray = [
//   {
//     id: 1,
//     bankName: "Access Bank Plc",
//   },
//   {
//     id: 2,
//     bankName: "Fidelity Bank Plc",
//   },
//   {
//     id: 3,
//     bankName: "First City Monument Bank Plc",
//   },
//   {
//     id: 4,
//     bankName: "First Bank of Nigeria Limited",
//   },
//   {
//     id: 5,
//     bankName: "Guaranty Trust Bank Plc",
//   },
//   {
//     id: 6,
//     bankName: "Union Bank of Nigeria Plc",
//   },
//   {
//     id: 7,
//     bankName: "United Bank for Africa Plc",
//   },
//   {
//     id: 8,
//     bankName: "Zenith Bank Plc",
//   },
//   {
//     id: 9,
//     bankName: "Citi Bank Nigeria Limited",
//   },
//   {
//     id: 10,
//     bankName: "Ecobank Nigeria Plc",
//   },
//   {
//     id: 11,
//     bankName: "Heritage Banking Company Limited",
//   },
//   {
//     id: 12,
//     bankName: "Keystone Bank Limited",
//   },
//   {
//     id: 13,
//     bankName: "Polaris Bank Limited",
//   },
//   {
//     id: 14,
//     bankName: "Stanbic IBTC Bank Plc",
//   },
//   {
//     id: 15,
//     bankName: "Standard Chartered",
//   },
//   {
//     id: 16,
//     bankName: "Sterling Bank Plc",
//   },
//   {
//     id: 17,
//     bankName: "Unity Bank Plc",
//   },
//   {
//     id: 18,
//     bankName: "Wema Bank Plc",
//   },
//   {
//     id: 19,
//     bankName: "Providus Bank Limited",
//   },
//   {
//     id: 20,
//     bankName: "Jaiz Bank Plc",
//   },
//   {
//     id: 21,
//     bankName: "SunTrust Bank Nigeria Limited",
//   },
// ];

// export const BankDropDown = () => {
//   return (
//     <DropdownContainer>
//       <DropDown>
//         {bankArray.map((values) => (
//           <Option key={values.key} value={values.bankName}>
//             {values.bankName}
//           </Option>
//         ))}
//       </DropDown>
//     </DropdownContainer>
//   );
// };

const Dropdown = styled.select`
  &&& {
    outline: none;
    border: none;
    background: transparent;
    width: 90%;
    font-size: 14px;
    color: black;

    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
`;
const Option = styled.option`
  background-color: ${activeShadow} !important;
`;

const DropdownContainer = styled.div`
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  background: ${inActiveShadow};
  border-radius: 4px;
  transition: ease-in all 0.3s;

  &:focus,
  &:active {
    background: ${activeShadow};
    box-shadow: 2px 3px 3px ${activeShadow};
  }
`;
