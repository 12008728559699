import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import { Forgot } from "./Style";
import { useHistory, useLocation } from "react-router-dom";
import { loginParticipant, clearLogin } from "../../redux-toolkit/loginSlice";
import { showModal } from "../../redux-toolkit/drawerSlice/forgotModalSlice";
import {
  HeroContainer,
  BigLogo,
  LTitle,
  Form,
  FormItem,
  TextInput,
  TextInputPassword,
  LoggerButton,
  Text1,
  Text2,
  IMail,
  ILock,
  Spinner,
  Notification,
  MessageNotificationError,
} from "../../globalComponent/Index";
import { AlertDialog } from "./ResendVerificationModal";
import { UseLoginMail } from "./context";
import { ForgotPasswordModal } from "../../globalComponent/Index";
import { setVisible } from "../../redux-toolkit/resendVerificationSlice";
import { fetchUserInfo } from "../../redux-toolkit/fetchUserInfoSlice";

const Login = () => {
  const notification = useSelector((state) => state.login.loginNotification);
  const content = useSelector((state) => state.login.loginContent);
  const token = useSelector((state) => state.login.token);
  const verified = useSelector((state) => state.login.verified);
  const error = useSelector((state) => state.login.error);

  const resendVerification = useSelector(
    (state) => state.resendVerification.visible
  );
  // console.log("resend visible", resendVerification);

  const dispatcher = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || {
    from: { pathname: "/dashboard", state: { from: location } },
  };
  const { email, setEmail } = UseLoginMail();

  useEffect(() => {
    if (notification === "success") {
      if (verified) {
        localStorage.setItem("token", token);
        if (!isNaN(parseInt(localStorage.externalSurveyLink))) {
          dispatcher(fetchUserInfo());
          history.push(
            `/dashboard/take-survey/${localStorage.externalSurveyLink}`
          );
        } else {
         history.push("/dashboard");
        }
        dispatcher(clearLogin());
      } else if (!verified) {
        dispatcher(setVisible());
      }
    }
    if (notification === "error") {
      MessageNotificationError("Check login credentials and try again");
    }
  }, [notification]);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const onFinish = (values) => {
    setEmail(values.email);
    dispatcher(loginParticipant(values));
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <HeroContainer>
      <AlertDialog />
      <Row justify="center">
        <Col xs={24} sm={12} md={11} lg={9} xl={6}>
          {" "}
          <BigLogo />
          <LTitle title="Participant Login" />
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <FormItem
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input Email",
                },
                {
                  type: "email",
                  message: "The format is not an email format",
                },
              ]}
            >
              <TextInput
                icon={<IMail />}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
            </FormItem>
            <FormItem
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password",
                },
              ]}
            >
              <TextInputPassword
                icon={<ILock />}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </FormItem>
            <ForgotPasswordModal />
            <Forgot onClick={() => dispatcher(showModal())}>
              Forgot Password
            </Forgot>
            {notification === "loading" ? (
              <Spinner />
            ) : (
              <LoggerButton htmlType="submit" text="LOGIN" />
            )}
          </Form>
          <Text1 onClick={() => history.push("/register")}>
            Don't have an account? <Text2>Create a new account</Text2>
          </Text1>
        </Col>
      </Row>
    </HeroContainer>
  );
};
export default Login;
