import React, { useState } from "react";
import styled from "styled-components";
import { ILocation } from "./ILocation";
import { Select } from "antd";
import { activeShadow, inActiveShadow } from "../globalAssets/theme";
import { FaLocationArrow } from "react-icons/fa";

const bankArray = [
  {
    id: 1,
    bankName: "Access Bank Plc",
  },
  {
    id: 2,
    bankName: "Fidelity Bank Plc",
  },
  {
    id: 3,
    bankName: "First City Monument Bank Plc",
  },
  {
    id: 4,
    bankName: "First Bank of Nigeria Limited",
  },
  {
    id: 5,
    bankName: "Guaranty Trust Bank Plc",
  },
  {
    id: 6,
    bankName: "Union Bank of Nigeria Plc",
  },
  {
    id: 7,
    bankName: "United Bank for Africa Plc",
  },
  {
    id: 8,
    bankName: "Zenith Bank Plc",
  },
  {
    id: 9,
    bankName: "Citi Bank Nigeria Limited",
  },
  {
    id: 10,
    bankName: "Ecobank Nigeria Plc",
  },
  {
    id: 11,
    bankName: "Heritage Banking Company Limited",
  },
  {
    id: 12,
    bankName: "Keystone Bank Limited",
  },
  {
    id: 13,
    bankName: "Polaris Bank Limited",
  },
  {
    id: 14,
    bankName: "Stanbic IBTC Bank Plc",
  },
  {
    id: 15,
    bankName: "Standard Chartered",
  },
  {
    id: 16,
    bankName: "Sterling Bank Plc",
  },
  {
    id: 17,
    bankName: "Unity Bank Plc",
  },
  {
    id: 18,
    bankName: "Wema Bank Plc",
  },
  {
    id: 19,
    bankName: "Providus Bank Limited",
  },
  {
    id: 20,
    bankName: "Jaiz Bank Plc",
  },
  {
    id: 21,
    bankName: "SunTrust Bank Nigeria Limited",
  },
];

// export const BankDropDown = () => {
//   const [value, setValue] = useState("");
//   const onChange = (e) => {
//     setValue(e.target.value);
//     console.log(e.target.value);
//   };
//   return (
//     // <DropdownContainer>
//     // <Dropdown>
//     {/* <Option /> */ }
//     <>
//       {bankArray.map((values) => (
//         <Option key={values.key} value={values.bankName}>
//           {values.bankName}
//         </Option>
//       ))
//     }
//     </>
//     // </Dropdown>
//     // </DropdownContainer>
//   );
// };

export const Dropdown = styled.select`
  &&& {
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    height: 30px;
    font-size: 14px;
    color: black;
    background: ${inActiveShadow};
    transition: ease-in all 0.3s;

    &:focus,
    &:active {
      outline: none;
      border: none;
      background: ${activeShadow};
      box-shadow: 2px 3px 3px ${activeShadow};
    }
  }
`;
export const Option = styled.option`
  background-color: ${activeShadow} !important;
`;

const DropdownContainer = styled.div`
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  background: ${inActiveShadow};
  border-radius: 4px;
  transition: ease-in all 0.3s;

  &:focus,
  &:active {
    box-shadow: 2px 3px 3px ${activeShadow};
  }
`;
