import axios from "axios";
import { domain } from "./domain";
import {
  userLoginRoute,
  userRegistrationRoute,
  fetchUserInfoRoute,
  updateLocationRoute,
  updateSettlementRoute,
  updateMonthlyIncomeRoute,
  updateGenderRoute,
  updateMaritalStatusRoute,
  updateHouseHoldRoute,
  updateDOBRoute,
  updateEducationRoute,
  updateIncomeSourceRoute,
  updatePhoneOwnershipRoute,
  fetchSurveysRoute,
  fetchSingleSurveyRoute,
  submitMainResponseRoute,
  submitScreeningResponseRoute,
  participantSurveyHistoryRoute,
  forgotPasswordRoute,
  updateAccountDetailsRoute,
  resendVerificationLinkRoute,
  updateProfilePicRoute,
  buyAirtime,
  transactionHistory,
  transferToBank
} from "./routes";

export class SideEffect {
  static async loginUser(payload) {
    const { email, password } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("email", email.toLowerCase());
    bodyFormData.append("password", password);
    const res = await axios({
      method: "post",
      url: `${domain}${userLoginRoute}`,
      data: bodyFormData,
    });
    return res;
  }
  static async registerUser(payload) {
    const {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      state,
      lga,
    } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("full_name", `${firstName} ${lastName}`);
    bodyFormData.append("email", email.toLowerCase());
    bodyFormData.append("password", password);
    bodyFormData.append("phone", phoneNumber);
    bodyFormData.append("state", state);
    bodyFormData.append("lga", lga);
    const res = await axios({
      method: "POST",
      url: `${domain}${userRegistrationRoute}`,
      data: bodyFormData,
    });
    return res;
  }
  static async fetchUserInfo() {
    const response = await axios({
      method: "GET",
      url: `${domain}${fetchUserInfoRoute}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async updateLocation(payload) {
    const { state, lga } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("state", `${state}`);
    bodyFormData.append("lga", `${lga}`);
    // console.log("the info is", bodyFormData);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateLocationRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async updateSettlement(payload) {
    const { settlement } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("settlement", `${settlement}`);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateSettlementRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }
  static async updateMaritalStatus(payload) {
    const { marrital_status } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("marrital_status", `${marrital_status}`);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateMaritalStatusRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }
  static async updateHouseHold(payload) {
    const { household } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("household", `${household}`);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateHouseHoldRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }
  static async updateGender(payload) {
    let bodyFormData = new FormData();

    bodyFormData.append("gender", `${payload.gender}`);
    // console.log("the gender", bodyFormData);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateGenderRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }
  static async updateDOB(payload) {
    const { date_of_birth } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("date_of_birth", `${date_of_birth}`);
    console.log("date_of_birth", date_of_birth);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateDOBRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }
  static async updateEducation(payload) {
    // const { education } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("education", payload.education);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateEducationRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async updateIncomeSource(payload) {
    const { income } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("income", `${income}`);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateIncomeSourceRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async updatePhoneOwnership(payload) {
    const { mobile_ownership } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("mobile_ownership", `${mobile_ownership}`);
    const response = await axios({
      method: "POST",
      url: `${domain}${updatePhoneOwnershipRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async updateMonthlyIncome(payload) {
    const { montly_income } = payload;
    let bodyFormData = new FormData();

    bodyFormData.append("montly_income", `${montly_income}`);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateMonthlyIncomeRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async fetchSurvey(payload) {
    const page = payload;

    const response = await axios({
      method: "GET",
      url: `${domain}${fetchSurveysRoute}${page}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async fetchSingleSurvey(payload) {
    // const page = payload;
    const response = await axios({
      method: "GET",
      url: `${domain}${fetchSingleSurveyRoute}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }
  static async submitMainResponse(payload) {
    const newFormData = new FormData();

    newFormData.set("survey_id", payload["survey_id"]);
    newFormData.set("data", JSON.stringify(payload["answerList"]));
    newFormData.set("user_id", payload["user_id"]);
    const response = await axios({
      method: "POST",
      url: `${domain}${submitMainResponseRoute}`,
      data: newFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    return response;
  }

  static async submitScreeningResponse(payload) {
    const newFormData = new FormData();

    newFormData.set("quetion_id", payload["survey_id"]);
    newFormData.set("answer", JSON.stringify(payload["answerList"]));
    newFormData.set("user_id", payload["user_id"]);

    const response = await axios({
      method: "POST",
      url: `${domain}${submitScreeningResponseRoute}`,
      data: newFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    return response;
  }

  static async fetchParticipantSurveyHistory(payload) {
    const response = await axios({
      method: "GET",
      url: `${domain}${participantSurveyHistoryRoute}${payload}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async forgotPassword(payload) {
    // const { email } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("email", payload.email.toLowerCase());
    const response = await axios({
      method: "POST",
      url: `${domain}${forgotPasswordRoute}`,
      data: bodyFormData,
    });
    return response;
  }

  static async updateAccountDetails(payload) {
    const { acct_name, acct_num, bank_name } = payload;
    let bodyFormData = new FormData();
    bodyFormData.append("acct_name", acct_name);
    bodyFormData.append("acct_num", acct_num);
    bodyFormData.append("bank_name", bank_name);

    const response = await axios({
      method: "POST",
      url: `${domain}${updateAccountDetailsRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async resendVerificationLink(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append("email", payload);
    const response = await axios({
      method: "POST",
      url: `${domain}${resendVerificationLinkRoute}`,
      data: bodyFormData,
    });
    return response;
  }

  static async updateProfilePic(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append("profile_image", payload);
    const response = await axios({
      method: "POST",
      url: `${domain}${updateProfilePicRoute}`,
      data: bodyFormData,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async buyAirtime(payload) {
    const response = await axios({
      method: "POST",
      url: `${domain}${buyAirtime}`,
      data: payload,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async fundBankAccount(payload) {
    const response = await axios({
      method: "POST",
      url: `${domain}${transferToBank}`,
      data: payload,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

  static async getTransactionHistory() {
    const response = await axios({
      method: "GET",
      url: `${domain}${transactionHistory}`,
      headers: {
        Authorization: `Token ${localStorage.token}`,
      },
    });
    return response;
  }

}
