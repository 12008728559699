import React from 'react';
import { MdLocationOn } from "react-icons/md";

import styled from "styled-components";
import { black, grey1 } from "../globalAssets/theme";
export const ILocation = () => {
  return <StyledProfile />;
};
export const ILocationIcon = () => {
  return <StyledLocation />;
};

const StyledProfile = styled(MdLocationOn)`
  font-size: 16px;
  ${"" /* padding: 2px; */}
  color: ${grey1};
  margin-left: 10px;
`;
const StyledLocation = styled(MdLocationOn)`
  font-size: 16px;
  ${"" /* padding: 2px; */}
  color: ${grey1};
`;
