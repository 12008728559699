import React from 'react';
import { IoHomeSharp } from "react-icons/io5";
import styled from "styled-components";
import { lightgreen, white, activeShadow } from "../globalAssets/theme";
export const IHome = () => {
  return <StyledHome />;
};

const StyledHome = styled(IoHomeSharp)`
  font-size: 18px;
  padding: 2px;
  color: ${white};
`;
