import styled from "styled-components";
import {} from "../../globalAssets/theme";
import { device } from "../../globalAssets/mediaQueries";

export const ContentContainer = styled.div`
  width: 100vw;
  padding: 7px;
  margin: 7px;
  background-color: white;
  border-radius: 8px;
  min-height: 100vh;
  @media ${device.laptop}{
    width: fit-content;
    min-width:40vw;
   // margin: 0 20px;
   // text-align:center;
  }
`
