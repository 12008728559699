import React from 'react';
import { MdAddCircle } from "react-icons/md";
import styled from "styled-components";
import { grey1, black, white, lightgreen2 } from "../globalAssets/theme";
import { device } from "../globalAssets/mediaQueries";
export const IAdd = () => {
  //   const history = useHistory();
  return <StyledAdd />;
};

const StyledAdd = styled(MdAddCircle)`
  ${"" /* margin-left: 10px; */}
  margin-top: 7px;
  right: 5px;
  background: ${lightgreen2};
  color: ${white};
  font-size: 17px;
  z-index: 500;
  position: absolute;
  @media ${device.laptop} {
    display: none;
  }
`;
