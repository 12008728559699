import React from 'react';
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { grey1, black } from "../globalAssets/theme";
import { device } from "../globalAssets/mediaQueries";


export const IMenu = ({ onClick }) => {
  return <StyledMenu onClick={onClick} />;
};

const StyledMenu = styled(MenuOutlined)`
  margin-left: 10px;
  margin-top: 7px;
  color: ${black};
  font-size: 16px;
  z-index: 500;
  position: absolute;
  @media ${device.laptop} {
    display: none;
  }
`;
