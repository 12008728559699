import React, { useEffect, useState } from "react";
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { Notification } from "../globalComponent/Notification";
import decryptNumberString from "../helperFunctions/decryptNumberString";

export const NavigateExternalSurveyLink = ({
  component: Component,
  ...reset
}) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();

  const { surveyId } = useParams();
 
  useEffect(() => {
    if (localStorage.token) {
      history.replace(`/dashboard/take-survey/${decryptNumberString(surveyId)}`);
    } else {
      Notification(
        "info",
        "Please Login",
        "Please login or register to continue"
      );
      localStorage.externalSurveyLink = decryptNumberString(surveyId);
      history.replace("/");
    }
  }, [surveyId]);

  return <div>...loading</div>;
};


