import React, { useEffect } from "react";
import styled from "styled-components";
import { Tag, Table, Statistic, Row, Col, Progress } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { device } from "../../globalAssets/mediaQueries";
import { fetchUserInfo } from "../../redux-toolkit/fetchUserInfoSlice";
import {
  CompoenentContainer,
  MobileHeader,
  IBackArrow,
  IPen,
  Avatar,
  IPerson,
  Form,
  LaptopHeader,
  FormItem,
  TextInputDisplay,
  IMail,
  HeroContainer,
  IPhone,
  ILocationIcon,
  FormItemDisplay,
} from "../../globalComponent/Index";
import { selectTransactions } from "../../redux-toolkit/transactionSlice";
import moment from "moment";

import { fontFamily } from "../../globalAssets/fontFamily";
import { lightgreen1 } from "../../globalAssets/theme";
const color = true;

const Profile = () => {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const history = useHistory();
  const dispatcher = useDispatch();
  const updateImageNotification = useSelector(
    (state) => state.updateImage.notification
  );

  const { allTransactions } = useSelector(selectTransactions);
  const { path, url } = useRouteMatch();
  const description = [
    {
      id: 1,
      title: "Full Name",
      value: `${userInfo && userInfo?.firstname} ${
        userInfo && userInfo?.lastname
      }`,
    },
    { id: 2, title: "Email", value: `${userInfo?.email}` },
    { id: 3, title: "Phone Number", value: `${userInfo?.phone_number}` },
    { id: 4, title: "State", value: `${userInfo?.state}` },
    { id: 5, title: "L.G.A", value: `${userInfo?.lga}` },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD Mo YYYY"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Statistic
          value={text}
          prefix={`${record.status === 2 ? "-" : ""}₦`}
          valueStyle={{
            fontSize: "16px",
            fontWeight: "400",
            color: record.status === 2 ? "red" : "black",
          }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        text === 0 ? (
          <Tag color="orange">Pending</Tag>
        ) : text === 1 ? (
          <Tag color="red">Failed</Tag>
        ) : (
          <Tag color="green">Completed</Tag>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) =>
        text === "A"
          ? "Airtime Recharge"
          : text === "B"
          ? "Transfer to Bank"
          : "",
    },
  ];
  useEffect(() => {
    if (updateImageNotification === "success") {
      dispatcher(fetchUserInfo());
    }
  }, [updateImageNotification]);


  return (
    <CompoenentContainer>
      <IBackArrow />
      <IPen onClick={() => history.push("/dashboard/updateprofile")} />
      <MobileHeader changeColor={color} title="Profile" />
      <LaptopHeader title="Profile" />
      <HeroContainer style={{ paddingTop: "0" }}>
        <MobileView>
          <Row gutter={[12, 12]}>
            <Col xs={24} lg={12}>
              <Form>
                <Avatar />
                {userInfo?.progress !== 100 && (
                  <UpdateInfoDiv
                    onClick={() => history.push("/dashboard/updateprofile")}
                  >
                    <div>
                      <FirstText>
                        Hi,
                        {`${userInfo?.firstname} ${userInfo?.lastname}`}
                      </FirstText>
                      <SecondText>Click here to complete profile</SecondText>
                    </div>
                    <Progress
                      strokeColor="white"
                      type="circle"
                      trailColor="grey"
                      percent={userInfo?.progress}
                      width={60}
                    />
                  </UpdateInfoDiv>
                )}
                <ShowModalDiv
                  onClick={() => history.push("/dashboard/updateprofile")}
                >
                  <Tag color="red">Update Profile</Tag>
                </ShowModalDiv>
                <FormItemDisplay label="Full Name">
                  <TextInputDisplay
                    value={`${userInfo && userInfo?.firstname} ${
                      userInfo && userInfo?.lastname
                    }`}
                    icon={<IPerson />}
                  />
                </FormItemDisplay>
                <FormItemDisplay label="Email">
                  <TextInputDisplay value={userInfo?.email} icon={<IMail />} />
                </FormItemDisplay>
                <FormItemDisplay label="Phone Number" name="phoneNumber">
                  <TextInputDisplay
                    value={userInfo?.phone_number}
                    icon={<IPhone />}
                  />
                </FormItemDisplay>
                <FormItemDisplay label="State">
                  <TextInputDisplay
                    value={userInfo?.state}
                    //   icon={<PhoneFilled className="site-form-item-icon" />}
                    icon={<ILocationIcon />}
                  />
                </FormItemDisplay>
                <FormItemDisplay label="LGA">
                  <TextInputDisplay
                    value={userInfo?.lga}
                    //   icon={<PhoneFilled className="site-form-item-icon" />}
                    icon={<ILocationIcon />}
                  />
                </FormItemDisplay>
              </Form>
            </Col>
            <Col lg={12} xs={24}>
              <TableLabel>Transaction History</TableLabel>
              <Table
                style={{ fontSize: "12px", fontFamily: fontFamily.Sora }}
                columns={columns}
                dataSource={allTransactions}
              />
            </Col>
          </Row>
        </MobileView>
        <LaptopView>
          <Row gutter={[16, 16]}>
            <Col
              style={{
                background: "white",
                borderRadius: "5px",
                boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
              }}
              xs={24}
              lg={12}
            >
              <div>
                <Avatar />
                <ShowModalDiv
                  style={{ margin: "10px 0" }}
                  onClick={() => history.push("/dashboard/updateprofile")}
                >
                  <Tag color="green">Update Profile</Tag>
                </ShowModalDiv>
                {userInfo?.progress !== 100 && (
                  <UpdateInfoDiv
                    onClick={() => history.push("/dashboard/updateprofile")}
                  >
                    <div>
                      <FirstText>
                        Hi,
                        {`${userInfo?.firstname} ${userInfo?.lastname}`}
                      </FirstText>
                      <SecondText>Click here to complete profile</SecondText>
                    </div>
                    <Progress
                      strokeColor="white"
                      type="circle"
                      trailColor="grey"
                      percent={userInfo?.progress}
                      width={60}
                    />
                  </UpdateInfoDiv>
                )}
                {description.map((desc) => (
                  <DescriptionItem style={{ marginTop: "10px" }} key={desc.key}>
                    <DescriptionTitle>{desc.title}</DescriptionTitle>
                    <DescriptionValue style> {desc.value}</DescriptionValue>
                  </DescriptionItem>
                ))}{" "}
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <TableLabel>Transaction History</TableLabel>
              <Table
                style={{ fontSize: "12px", fontFamily: fontFamily.Sora }}
                columns={columns}
                dataSource={allTransactions}
              />
            </Col>
          </Row>
        </LaptopView>
      </HeroContainer>
    </CompoenentContainer>
  );
};
const UpdateInfoDiv = styled.div`
  width: 100%;
  padding: 10px;
  height: 80px;
  background: purple;
  border-radius: 5px;
  display: flex;
  margin: 10px 0;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;
const FirstText = styled.h3`
  font-family: ${fontFamily.Sora};
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: white;
`;

const SecondText = styled.h4`
  font-family: ${fontFamily.inter};
  font-size: 10px;
  text-align: left;
  color: white;
`;

const ShowModalDiv = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    cursor: pointer;
  }
`;
const LaptopView = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    padding: 0px 10px;
    ${"" /* text-align: left; */}
  }
`;
const MobileView = styled.div`
  display: block;
  @media ${device.laptop} {
    display: none;
  }
`;
const DescriptionItem = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
`;
const DescriptionTitle = styled.h5`
  ${"" /* color: #5a5a5a; */}
  font-family:${fontFamily.Sora};
  font-size: 13px;
`;
const DescriptionValue = styled.p`
  ${"" /* color: #000000; */}
  font-size: 13px;
  font-family: ${fontFamily.Sora};

  text-align: center;
`;

const TableLabel = styled.div`
  width: 100%;
  border-radius: 5px;
  background: ${lightgreen1};
  color: white;
  font-family: ${fontFamily.Sora};
  font-weight: bold;
  padding: 10px 10px;
`;

export default Profile;
