import React from 'react';
import styled from "styled-components";
export const CompoenentContainer = (props) => {
  return <HeroContainerStyle>{props.children}</HeroContainerStyle>;
};

const HeroContainerStyle = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
`;
