import React from 'react';
import { MdDashboard } from "react-icons/md";

import styled from "styled-components";
import { black } from "../globalAssets/theme";
export const IDashboard = () => {
  return <StyledDashboard />;
};

const StyledDashboard = styled(MdDashboard)`
  font-size: 20px;
  ${"" /* padding: 2px; */}
  color: ${black};
`;

export const DashboardIcon = () => {
  return <DashIcon />;
};
const DashIcon = styled(MdDashboard)`
  font-size: 1vw;
  margin: 0 10px 0 0;
`;
