import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

const initialState = {
  registerNotification: "",
  loading: "",
  error: "",
};

export const registerParticipant = createAsyncThunk(
  "register/participant",
  async (payload, { rejectWithValue }) => {
    try {
      const registerUser = await SideEffect.registerUser(payload);

      return registerUser.data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    clearRegister: (state) => {
      state.registerNotification = "";
    },
  },
  extraReducers: {
    [registerParticipant.fulfilled]: (state, action) => {
      state.registerNotification = "success";
      state.loading = "done";
    },
    [registerParticipant.pending]: (state, { payload }) => {
      state.registerNotification = "loading";
      state.loading = "loading";
    },
    [registerParticipant.rejected]: (state, { error }) => {
      state.registerNotification = "error";
      state.error = error;
      // console.log("register error", error);
    },
  },
});
// export const loginInfo = createSelector(
//   (state) => ({
//     registerNotification: "",
//     loading: "",
//     error: "",
//   }),
//   (state) => state
// );
export default registerSlice.reducer;
export const { clearRegister } = registerSlice.actions;
