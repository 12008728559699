import React from 'react';
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { black, white } from "../globalAssets/theme";
import { device } from "../globalAssets/mediaQueries";
export const IBackArrow = () => {
  const history = useHistory();
  return <StyledBackArrow onClick={() => history.goBack()} />;
};
export const IBackArrowBlack = () => {
  const history = useHistory();
  return <StyledBackArrowBlack onClick={() => history.goBack()} />;
};

const StyledBackArrow = styled(BiArrowBack)`
  margin-left: 10px;
  margin-top: 7px;
  color: ${white};
  font-size: 16px;
  z-index: 500;
  position: absolute;
  @media ${device.laptop} {
    display: none;
  }
`;

const StyledBackArrowBlack = styled(BiArrowBack)`
  margin-left: 10px;
  margin-top: 7px;
  color: ${black};
  font-size: 16px;
  z-index: 500;
  position: absolute;
  @media ${device.laptop} {
    display: none;
  }
`;
