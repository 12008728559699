import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  CompoenentContainer,
  MobileHeader,
  LaptopHeader,
  IBackArrow,
  IAdd,
  AddAccount,
  LoggerButton,
} from "../../globalComponent/Index";
import { Tag } from "antd";
import { showModal } from "../../redux-toolkit/AddAccountSlice";
import {
  DivContainer,
  RedeemableDiv,
  LifetimeDiv,
  DivTitle,
  StatVal,
  TextSmallDiv,
  TextSmall,
} from "./Style";
import { Row, Col } from "antd";
import { device } from "../../globalAssets/mediaQueries";
import { Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import TransactionBoard from '../Transactions'

const MyWallet = () => {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const visible = useSelector((state) => state.addAccount.visible);
  const dispatcher = useDispatch();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const color = true;


  return (
    <Switch>
      <Route exact path={path}>
    <CompoenentContainer>
      <IBackArrow />
      <div onClick={() => dispatcher(showModal())}>
        <IAdd />
      </div>
      <AddAccount />
      <MobileHeader changeColor={color} title="My Wallet" />
      <LaptopHeader title="My Wallet" />
      <div
        style={{
          padding: "20px 20px",
          margin: "30px auto",
          textAlign: "center",
        }}
      >
        <DivContainer>
        <DivTitle>Wallet</DivTitle>
          <RedeemableDiv>
            <StatVal prefix="₦" value={userInfo.wallet} />
          </RedeemableDiv>

        {/*   <DivTitle>Redeemable</DivTitle>
          <RedeemableDiv>
            <StatVal prefix="₦" value={userInfo.wallet} />
          </RedeemableDiv> */}
          {/* </DivContainer>

        <DivContainer> */}
          <DivTitle>LifeTime Points</DivTitle>
          <LifetimeDiv>
            <StatVal value={userInfo.points} />
          </LifetimeDiv>{" "}
          <TextSmall>Convert Points to Cash (When Points = ₦1000) </TextSmall>
        </DivContainer>
        <Row justify="center">
          <Col xs={24} sm={24} md={12}>
            {/* <ShowModalDiv onClick={() => dispatcher(showModal())}>
              <Tag color="red">Add Account Details</Tag>
            </ShowModalDiv> */}
            <LoggerButton onClick={() => history.push('/dashboard/transaction')} size={"large"} text="Request Withdrawal" />
          </Col>
        </Row>

        <TextSmallDiv>
          Please note that you must earn at least 1000 naira before your request
          can be processed
        </TextSmallDiv>
      </div>
    </CompoenentContainer>
    </Route>
   
    </Switch>
  );
};

const ShowModalDiv = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    cursor: pointer;
  }
`;
export default MyWallet;
