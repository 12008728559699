import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { QuestionBoard } from "./QuestionComponent/QuestionBoard";
import { lightgreen1, white } from "../../globalAssets/theme";
import { device } from "../../globalAssets/mediaQueries";
import {
  RadioGroupComponent,
  CheckboxComponent,
  SingleInputComponent,
  DropDownComponent,
  MatrixComponent,
} from "./questionGroups";

import {
  CompoenentContainer,
  IBackArrowBlack,
} from "../../globalComponent/Index";

import { Button, Modal } from "antd";
// import { BackButton } from "../../globalComponents/backButton";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";
import {
  clearSurveyList,
  fetchParticipantSingleSurveyAction, //fetch survey that has screening quesstion and survey
  //mainResponseNotifyAction, //response from submitting questionaire
  // screeningResponseNotifyAction, //response from screening
  // submitMainResponseAction,
  // submitScreeningResponseAction,
} from "../../redux-toolkit/fetchParticipantSurveys";
import { fetchUserInfo } from "../../redux-toolkit/fetchUserInfoSlice";

import {
  mainResponseNotifyAction,
  submitMainResponseAction,
} from "../../redux-toolkit/submitMainResponse";

import {
  submitScreeningResponseAction,
  screeningResponseNotifyAction,
} from "../../redux-toolkit/submitScreeningResponse";

import {
  UseSurveyQuestionAnswerContext,
  UseActiveParticipantId,
} from "./context";
import {
  LaptopHeader,
  MobileHeader,
  Notification,
} from "../../globalComponent/Index";
import { AlertDialog } from "./successModal";

export const SurveyGround = (props) => {
  const { id } = useParams();
  const { url, path } = useRouteMatch();

 
  const participantSingleSurvey = useSelector(
    (state) => state.participantSingleSurvey
  );
  // console.log("participantSinglesurvey is", participantSingleSurvey);
  const [activeQuestionSet, setActiveQuestionSet] = useState([]);

  /*  const [questionAnswer, setQuestionAnswer] = useState([]); */
  const [responseType, setResponseType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatcher = useDispatch();
  /*   const inputRef = useRef(); */
  const history = useHistory();
  const { questionAnswer, setQuestionAnswer } =
    UseSurveyQuestionAnswerContext();
  const { activeParticipantId, userInfo } = UseActiveParticipantId();
  const [matrixQuestionAnswer, setMatrixQuestionAnswer] = useState([]);
  const mainResponseNotify = useSelector((state) => state.mainResponseNotify);
  const screeningResponseNotify = useSelector(
    (state) => state.screeningResponseNotify.screeningResponseNotify
  );

  // console.log({ matrixQuestionAnswer });
  useEffect(() => {
    localStorage.removeItem("externalSurveyLink");
    dispatcher(fetchParticipantSingleSurveyAction(id));
  }, []);

  // console.log("screening result is", screeningResponseNotify);
  // console.log("questionaire result is", mainResponseNotify);

  useEffect(() => {
    if (participantSingleSurvey.screening_question.length > 0) {
      setActiveQuestionSet(participantSingleSurvey.screening_question);
      setResponseType("screeningQuestion");
    } else {
      setActiveQuestionSet(participantSingleSurvey.questionnaire);
      setResponseType("questionnaire");
    }
  }, [participantSingleSurvey]);

  useEffect(() => {
    console.log({ screeningResponseNotify, participantSingleSurvey });
    if (screeningResponseNotify.status !== "") {
      if (screeningResponseNotify.status === "success") {
        if (screeningResponseNotify.result) {
          setActiveQuestionSet(participantSingleSurvey.questionnaire);
          setResponseType("questionnaire");
          setMatrixQuestionAnswer([]);
          setQuestionAnswer([]);
        } else {
         // setIsModalVisible(true);
        }
        // dispatcher(fetchUserInfo());
      }

      if (screeningResponseNotify.status === "error") {
        Notification("error", "Failed", "Please try again");
      }
      dispatcher(screeningResponseNotifyAction({ status: "", result: null }));
    }
  }, [
    dispatcher,
    participantSingleSurvey,
    participantSingleSurvey.questionnaire,
    screeningResponseNotify,
    setQuestionAnswer,
  ]);

  useEffect(() => {
 
    if (mainResponseNotify.mainResponseNotify === 'success') {

        setIsModalVisible(true);
        // dispatcher(fetchUserInfo());
        dispatcher(clearSurveyList());
    }

    if (mainResponseNotify.mainResponseNotify === "error") {
      console.log("mainResponseNotify", mainResponseNotify )
      Notification("error", "Submission failed!", "Please try again later");
    }

    setMatrixQuestionAnswer([]);
    setQuestionAnswer([]);

    dispatcher(mainResponseNotifyAction(""));
  }, [dispatcher, mainResponseNotify, setQuestionAnswer]);

  const handleChange = (e, type, id) => {
    const temporalQuestionAnswer = questionAnswer.slice();

    let singleAnswer =
      type === "checkbox" ? e : type === "dropDown" ? [e] : [e.target.value];
    if (temporalQuestionAnswer.some((item) => item.question_id === id)) {
      const newQuestionAnswer = temporalQuestionAnswer.map((answer) => {
        if (answer.question_id === id) {
          return { question_id: id, answer: singleAnswer, question_type: type };
        }
        return answer;
      });

      setQuestionAnswer(newQuestionAnswer);
    } else {
      setQuestionAnswer([
        ...temporalQuestionAnswer,
        { question_id: id, answer: singleAnswer, question_type: type },
      ]);
    }
  };

  const handleMatrixChange = (option, category, inputRef, questionId) => {
    // console.log(option, category, questionId);
    const previousAnswer = matrixQuestionAnswer.slice();
    const activeAnswerObject = previousAnswer.find(
      (item) => item.question_id === questionId
    );
    if (activeAnswerObject) {
      let activeAnswer = activeAnswerObject?.answer?.map((item) => {
        if (item.category === category) {
          return { category, answer: option };
        }

        return item;
      });

      if (!activeAnswer.some((item) => item.category === category)) {
        activeAnswer = [...activeAnswer, { category, answer: option }];
      }

      activeAnswerObject.answer = activeAnswer;

      const newMatrixQuestionAnswer = previousAnswer.map((item) => {
        if (item.question_id === activeAnswerObject.question_id) {
          return activeAnswerObject;
        }

        return item;
      });

      setMatrixQuestionAnswer(newMatrixQuestionAnswer);
    } else {
      let newAnswer = {
        question_id: questionId,
        answer: [{ category, answer: option }],
        question_type: "matrix",
      };
      setMatrixQuestionAnswer([...previousAnswer, newAnswer]);
    }

    inputRef.current.checked = true;
  };

  const handleSubmit = () => {
    if (responseType === "screeningQuestion") {
      // console.log([...questionAnswer, ...matrixQuestionAnswer]);
      dispatcher(
        submitScreeningResponseAction({
          survey_id: id,
          answerList: [...questionAnswer, ...matrixQuestionAnswer],
          user_id: userInfo?.id,
        })
      );
      //dispatcher(clearSurveyList());
    } else if (responseType === "questionnaire") {
      //setIsModalVisible(true);
      dispatcher(
        submitMainResponseAction({
          survey_id: id,
          answerList: [...questionAnswer, ...matrixQuestionAnswer],
          user_id: userInfo?.id,
        })
      );
      /*  setMatrixQuestionAnswer([]);
            setQuestionAnswer([]); */
     
    }
  };

  const questionList = activeQuestionSet?.map((item, index) => {
    if (item.question_type === "radio_group") {
      return (
        <RadioGroupComponent
          questionNumber={index + 1}
          question={item.question}
          key={index}
          options={item.options}
          onChange={(e) =>
            handleChange(e, item.question_type, item.question_id)
          }
        />
      );
    }

    if (item.question_type === "checkbox") {
      return (
        <CheckboxComponent
          key={index}
          questionNumber={index + 1}
          question={item.question}
          options={item.options}
          onChange={(e) =>
            handleChange(e, item.question_type, item.question_id)
          }
        />
      );
    }

    if (item.question_type === "dropDown") {
      return (
        <DropDownComponent
          key={index}
          questionNumber={index + 1}
          question={item.question}
          options={item.options}
          onChange={(e) =>
            handleChange(e, item.question_type, item.question_id)
          }
        />
      );
    }

    if (item.question_type === "single_input") {
      return (
        <SingleInputComponent
          key={index}
          questionNumber={index + 1}
          question={item.question}
          onChange={(e) =>
            handleChange(e, item.question_type, item.question_id)
          }
        />
      );
    }

    if (item.question_type === "matrix") {
      return (
        <MatrixComponent
          onChange={(option, category, inputRef) =>
            handleMatrixChange(option, category, inputRef, item.question_id)
          }
          categories={item.category}
          options={item.options}
          questionNumber={index + 1}
          question={item.question}
          key={index}
        />
      );
    }
  });

  const handleOk = () => {
    setIsModalVisible(false);
    history.push("/dashboard");
  };

  return (
    <CompoenentContainer>
      <IBackArrowBlack />
      <LaptopHeader title="Take Survey" />
      <MobileHeader title="Take Survey" />
      <StyledSurveyGround>
        <QuestionBoard title="Questionnaire">{questionList}</QuestionBoard>
        <PrimaryButtonComponent buttonText="Submit" onClick={handleSubmit} />

        <AlertDialog open={isModalVisible} handleClose={handleOk} />
      </StyledSurveyGround>
    </CompoenentContainer>
  );
};

const PrimaryButtonComponent = (props) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <StyledButton size="large" loading={false} onClick={props.onClick}>
        {props.buttonText}
      </StyledButton>
    </div>
  );
};

const StyledButton = styled(Button)`
  border-radius: 10px;
  /* padding: 13px 14px; */
  /* padding-bottom: 26px; */
  background-color: ${lightgreen1};
  color: #fff;
  font-weight: 500;
  border: none;
  font-size: 16px;
  cursor: pointer;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  letter-spacing: 0.2px;
  margin-top: 30px;

  &:hover,
  &:focus {
    background-color: ${lightgreen1};
    color: #fff;
    outline: none;
  }

  &:hover {
    box-shadow: none;
  }

  @media ${device.laptop} {
    max-width: 50%;
    font-size: 1.2vw;
  }

  @media ${device.desktopL} {
    padding: 20px 17px;
  }
`;

const StyledSurveyGround = styled.div`
  width: 90%;
  margin: 10px auto;

  @media ${device.laptop} {
    ${"" /* width: 50%; */}
    max-width:70%;
    margin: 10px auto;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${lightgreen1};
    border: 1px solid ${lightgreen1};
  }

  & .ant-radio-checked .ant-radio-inner {
    border: 1px solid ${lightgreen1};
  }

  & .ant-radio-inner::after {
    background-color: ${lightgreen1};
  }

  & .ant-input {
    border-radius: 5px;
  }

  & .ant-input:hover,
  & .ant-input:focus {
    border-color: ${lightgreen1};
    outline: 0;
    box-shadow: none;
  }

  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  & .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${lightgreen1};
  }
`;

const StyledSingQuestion = styled.div`
  margin: 10px;

  & > div:nth-child(2) {
    margin-left: 20px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  /*   padding: 10px 10px 0px 10px; */
  font-size: 16px;

  & p:first-child {
    color: #00000029;
    margin-right: 10px;
  }

  @media ${device.laptop} {
    font-size: 1.15vw;
  }
`;
