import React from 'react';
import styled from "styled-components";
import { Checkbox as AntCheck } from "antd";
import { grey1, lightgreen } from "../globalAssets/theme";



export const Checkbox = (props) => {
  return <StyledBox onChange={(e) => props.onChange} />;
};

const StyledBox = styled.input.attrs({ type: "checkbox" })`
  & .checked + label {
    border: 2px solid ${lightgreen};
    color: ${lightgreen};
    background: ${lightgreen};
  }
`;
