import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

const initialState = {
  fetchuserInfoNotification: "",
  error: "",
  userInfo: {},
};

export const fetchUserInfo = createAsyncThunk(
  "fetchUserInfo/participant",
  async (payload, { rejectWithValue }) => {
    try {
      const userInfo = await SideEffect.fetchUserInfo();
      localStorage.setItem('userInfo', JSON.stringify(userInfo.data.data));
      return userInfo.data.data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const fetchUserInfoSlice = createSlice({
  name: "fetchUserInfo",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserInfo.fulfilled]: (state, { payload }) => {
      state.fetchuserInfoNotification = "success";
      state.userInfo = payload;
    },
    [fetchUserInfo.pending]: (state, { payload }) => {
      state.loginNotification = "loading";
      state.fetchuserInfoNotification = "";
    },
    [fetchUserInfo.rejected]: (state, { error }) => {
      state.loginNotification = "error";
      state.error = error;
    },
  },
});

export default fetchUserInfoSlice.reducer;
