import React from 'react';
import { IoPersonCircleSharp } from "react-icons/io5";

import styled from "styled-components";
import { black, grey1 } from "../globalAssets/theme";
export const IPerson = () => {
  return <StyledProfile />;
};

const StyledProfile = styled(IoPersonCircleSharp)`
  font-size: 20px;
  ${"" /* padding: 2px; */}
  color: ${grey1};
`;
