import React from 'react';
import { IoMdLock } from "react-icons/io";

import styled from "styled-components";
import { black, grey1 } from "../globalAssets/theme";
export const ILock = () => {
  return <StyledProfile />;
};

const StyledProfile = styled(IoMdLock)`
  font-size: 18px;
  ${"" /* padding: 2px; */}
  color: ${grey1};
`;
