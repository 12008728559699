import React from 'react';
import { notification } from "antd";


export const Notification = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const LongNotification = (type, message, description) => {
  notification.open({
    type,
    message,
    description,
    duration: 30000,
  });
};
