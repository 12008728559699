import React from 'react';
import { message } from "antd";

import styled from "styled-components";

export const MessageNotificationSuccess = (description) => {
  message.success(description, 5);
};

export const MessageNotificationError = (description) => {
  message.error(description, 5);
};

export const MessageNotificationErrorLong = (description) => {
  message.error(description, 20);
};

export const MessageNotificationSuccessLong = (description) => {
  message.success(description, 20);
};

export const MessageNotificationSuccessShort = (description) => {
  message.success(description, 2);
};

export const MessageNotificationErrorShort = (description) => {
  message.error(description, 3);
};
