import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { lightgreen1 } from "../globalAssets/theme";

export const Spinner = () => {
  return <StyledSpinner size="small" style={{ color: `${lightgreen1}` }} />;
};
const StyledSpinner = styled(Spin)`
  color: ${lightgreen1};
  font-size: 14px;
  .ant-spin {
    color: ${lightgreen1};
  }
`;
