import styled from "styled-components";
import { fontFamily } from "../../globalAssets/fontFamily";
import { device } from "../../globalAssets/mediaQueries";
import {
  activeShadow,
  white,
  inActiveShadow,
  grey2,
  black,
  lightgreen,
  darkgreen,
  lightgreen2,
  lightgreen1,
  activeShadow2,
  background,
} from "../../globalAssets/theme";
export const IconContainer = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: ${darkgreen};
  text-align: center;
`;
export const FooterContainer = styled.div`
  width: 100%;
  padding: 0 15px;
  background: ${white};
  position: fixed;
  box-shadow: -2px 6px 30px -2px ${activeShadow2};
  ${"" /* margin-top: 80px; */}
  left: 0;
  bottom: 0;
  height: 40px;
  @media ${device.laptop} {
    display: none;
  }
`;
export const Footername = styled.h5`
  font-family: ${fontFamily.inter};
  font-size: 9px;
  font-weight: bold;
  line-height: 1px;
  color: ${black};
  margin: 5px 0;
`;

export const FooterHolder = styled.div`
  display: flex;
  margin-top: 7px;
  flex-direction: column;
  padding: 0 0 0 0;
  align-items: center;
`;

export const DrawerContainer = styled.div`
  background: ${white};
`;
export const DrawerHeader = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${lightgreen1};
  padding: 20px;
  padding-left: 12px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

  @media ${device.laptop} {
    display: none;
  }
`;
export const TitleName = styled.h4`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: ${white};
  font-family: ${fontFamily.Sora};
`;
export const TitleAccount = styled.h5`
  font-size: 10px;
  color: ${white};
  font-style: italic;
  line-height: 0px;
  font-family: ${fontFamily.Sora};
`;
export const DrawerLink = styled.h4`
  color: ${black};
  font-family: ${fontFamily.inter};
  ${"" /* font-weight: bold; */}
  font-weight:600;
  margin-left: 12px;

  font-size: 12px;
`;
export const DrawerLinkContainer = styled.div`
  display: flex;
  width: 100%;
  ${"" /* @media ${device.laptop} { */}
  background: ${background};
  ${"" /* } */}

  ${"" /* margin-top: 10px; */}
  ${"" /* align-items: center; */}
`;
