import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "./drawerSlice/drawerSlice";
import loginReducer from "./loginSlice";
import registerReducer from "./registerSlice";
import fetchUSerInfoReducer from "./fetchUserInfoSlice";
import updateProfileReducer from "./updateProfileSlice";
import surveyListReducer from "./fetchSurveysListSlice";
import participantSurveyReducer from "./fetchParticipantSurveys";
import mainResponseNotifyReducer from "./submitMainResponse";
import screeningResponseNotifyReducer from "./submitScreeningResponse";
import surveyHistoryReducer from "./fetchSurveyHistory";
import modalReducer from "./drawerSlice/forgotModalSlice";
import forgotPasswordReducer from "./forgotPassword";
import addAccountReducer from "./AddAccountSlice";
import resendVerificationReducer from "./resendVerificationSlice";
import updateImageReducer from "./updateImageSlice";
import transactionReducer from './transactionSlice';

export default configureStore({
  reducer: {
    drawer: drawerReducer,
    login: loginReducer,
    register: registerReducer,
    userInfo: fetchUSerInfoReducer,
    updateProfile: updateProfileReducer,
    surveyList: surveyListReducer,
    participantSingleSurvey: participantSurveyReducer,
    mainResponseNotify: mainResponseNotifyReducer,
    screeningResponseNotify: screeningResponseNotifyReducer,
    surveyHistory: surveyHistoryReducer,
    modalValue: modalReducer,
    forgotPassword: forgotPasswordReducer,
    addAccount: addAccountReducer,
    resendVerification: resendVerificationReducer,
    updateImage: updateImageReducer,
    transactions: transactionReducer
  },
});
