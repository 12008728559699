import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { SideEffect } from "../api/sideEffects";
const initialState = {
  updateStatus: "",
  error: "",
  updateNotification: "",
};

export const updateLocation = createAsyncThunk(
  "updateProfile/location",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedLocation = await SideEffect.updateLocation(payload);
      // console.log("the payload", payload);
      // console.log("update Location", SideEffect.updateLocation);
      return updatedLocation;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updateSettlement = createAsyncThunk(
  "updateProfile/settlement",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedSettlement = await SideEffect.updateSettlement(payload);
      return updatedSettlement;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updateMaritalStatus = createAsyncThunk(
  "updateProfile/maritalStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedMaritalStatus = await SideEffect.updateMaritalStatus(
        payload
      );
      return updatedMaritalStatus;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updateHouseHold = createAsyncThunk(
  "updateProfile/household",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedHouseHold = await SideEffect.updateHouseHold(payload);
      return updatedHouseHold;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updateGender = createAsyncThunk(
  "updateProfile/gender",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedGender = await SideEffect.updateGender(payload);
      return updatedGender;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updateDOB = createAsyncThunk(
  "updateProfile/dob",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedDob = await SideEffect.updateDOB(payload);
      return updatedDob;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updateEducation = createAsyncThunk(
  "updateProfile/education",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedEducation = await SideEffect.updateEducation(payload);
      return updatedEducation;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updateIncomeSource = createAsyncThunk(
  "updateProfile/income",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedIncomeSource = await SideEffect.updateIncomeSource(payload);
      return updatedIncomeSource;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
export const updatePhoneOwnership = createAsyncThunk(
  "updateProfile/phoneownership",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedPhoneOwnerShip = await SideEffect.updatePhoneOwnership(
        payload
      );
      return updatedPhoneOwnerShip;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updatedMonthlyIncome = createAsyncThunk(
  "updateProfile/monthlyIncome",
  async (payload, { rejectWithValue }) => {
    try {
      const updatedMonhlyIncome = await SideEffect.updatePhoneOwnership(
        payload
      );
      return updatedMonhlyIncome;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const updateProfile = createSlice({
  name: "updateProfile",
  initialState,
  reducers: {
    clearNotification: (state) => {
      state.updateNotification = "";
    },
  },
  extraReducers: {
    [updateLocation.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.updateStatus = "Location updated successfully";
      // console.log(payload);
    },
    [updateLocation.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [updateLocation.rejected]: (state, { error }) => {
      state.updateNotification = "error";
      state.updateStatus = "Location not Updated, please try again!";
    },
    [updateSettlement.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.updateStatus = "Settlement updated successfully";
      // console.log(payload);
    },
    [updateSettlement.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [updateSettlement.rejected]: (state, { error }) => {
      state.updateNotification = "error";
      state.updateStatus = "Settlement not Updated, please try again!";
    },
    [updateMaritalStatus.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.updateStatus = "Marital Status updated successfully";
      // console.log(payload);
    },
    [updateMaritalStatus.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [updateMaritalStatus.rejected]: (state, { error }) => {
      state.updateNotification = "error";
      state.updateStatus = "Marital Status not Updated, please try again!";
    },

    [updateHouseHold.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.updateStatus = "Household updated successfully";
      // console.log(payload);
    },
    [updateHouseHold.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [updateHouseHold.rejected]: (state, { error }) => {
      state.updateNotification = "error";
      state.updateStatus = "Household not Updated, please try again!";
    },

    [updateGender.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.updateStatus = "Gender updated successfully";
      // console.log("gender", payload);
    },
    [updateGender.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [updateGender.rejected]: (state, { error }) => {
      state.updateNotification = "error";
      state.updateStatus = "Gender not Updated, please try again!";
      // console.log("the gender error is", error);
    },

    [updateDOB.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.updateStatus = "Date of birth updated successfully";
      // console.log(payload);
    },
    [updateDOB.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [updateDOB.rejected]: (state, { error }) => {
      state.updateNotification = "error";
      state.updateStatus = "Date of birth not Updated, please try again!";
    },
  },

  [updateEducation.fulfilled]: (state, { payload }) => {
    state.updateNotification = "success";
    state.updateStatus = "Education updated successfully";
    console.log("this is the update for education success", payload);
  },
  [updateEducation.pending]: (state, { payload }) => {
    state.updateNotification = "loading";
    console.log("this is the update for education loading", payload);
  },
  [updateEducation.rejected]: (state, { error }) => {
    state.updateNotification = "error";
    // console.log("this is the update for education success", error);

    state.updateStatus = "Education not Updated, please try again!";
  },

  [updateIncomeSource.fulfilled]: (state, { payload }) => {
    state.updateNotification = "done";
    state.updateStatus = "Income Source updated successfully";
    // console.log(payload);
  },
  [updateIncomeSource.pending]: (state, { payload }) => {
    state.updateNotification = "loading";
  },
  [updateIncomeSource.rejected]: (state, { error }) => {
    state.updateNotification = "error";
    state.updateStatus = "Income Source not Updated, please try again!";
  },

  [updatePhoneOwnership.fulfilled]: (state, { payload }) => {
    state.updateNotification = "done";
    state.updateStatus = "Phone Ownership updated successfully";
    // console.log(payload);
  },
  [updatePhoneOwnership.pending]: (state, { payload }) => {
    state.updateNotification = "loading";
  },
  [updatePhoneOwnership.rejected]: (state, { error }) => {
    state.updateNotification = "error";
    state.updateStatus = "Phone Ownership not Updated, please try again!";
  },

  [updatedMonthlyIncome.fulfilled]: (state, { payload }) => {
    state.updateNotification = "done";
    state.updateStatus = "Monthly income updated successfully";
    // console.log(payload);
  },
  [updatedMonthlyIncome.pending]: (state, { payload }) => {
    state.updateNotification = "loading";
  },
  [updatedMonthlyIncome.rejected]: (state, { error }) => {
    state.updateNotification = "error";
    state.updateStatus = "Monthly income not Updated, please try again!";
  },
});
export default updateProfile.reducer;
export const { clearNotification } = updateProfile.actions;
