import React from "react";
import styled from "styled-components";
import { activeShadow, inActiveShadow } from "../globalAssets/theme";
import { Input } from "antd";
import "./antdesign.css";
import { fontFamily } from "../globalAssets/fontFamily";

export const TextInput = ({
  type,
  placeholder,
  name,
  icon,
  onChange,
  max,
  min,
}) => {
  return (
    <StyledInput
    size='large'
      type={type}
      maxLength={max}
      name={name}
      prefix={icon}
      bordered={false}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export const TextInputPassword = ({
  type,
  placeholder,
  name,
  icon,
  onChange,
}) => {
  return (
    <StyledInputPassword
      type={type}
      name={name}
      prefix={icon}
      bordered={false}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export const TextInputDisplay = ({ icon, value }) => {
  return <StyledDisplayInput value={value} bordered={false} prefix={icon} />;
};

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
    cursor: pointer;
    background: ${inActiveShadow};
    line-height: 1px;
    transition: ease-in all 0.3s;
    border: none;
    outline: none;
    border-radius: 4px;
    &:focus,
    &:active,
    &:hover {
      background: ${activeShadow};

      box-shadow: 2px 3px 3px ${activeShadow};
    }
  }
`;

const StyledDisplayInput = styled(Input)`
  &&& {
    width: "100%";
    ${"" /* height: 15px; */}
    cursor: pointer;
    background: ${inActiveShadow};
    transition: ease-in all 0.3s;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    font-weight: bold;
    ${"" /* font-size: */}
    & .ant-input {
      font-size: 12px;
      padding-left: 5px;
      font-family: ${fontFamily.inter};
      ${"" /* font-weight: 500; */}
    }

    font-family: ${fontFamily.inter};
    &:focus,
    &:active,
    &:hover {
      background: ${activeShadow};

      box-shadow: 2px 3px 3px ${activeShadow};
    }
  }
`;

const StyledInputPassword = styled(Input.Password)`
  &&& {
    width: "100%";
    cursor: pointer;
    border-radius: 4px;
    background: ${inActiveShadow};
    transition: ease-in all 0.3s;
    border: none;
    outline: none;
    ${"" /* box-shadow: 2px 3px 3px ${inActiveShadow}; */}
    &:focus,
  &:active,
  &:hover {
      background: ${activeShadow};

      box-shadow: 2px 3px 3px ${activeShadow};
    }
  }
`;
