import React from 'react';
import styled from "styled-components";
import { NavLink as RouteNavLink } from "react-router-dom";
import { lightgreen } from "../globalAssets/theme";

export const NavLink = (props) => {
  return (
    <StyledNavLink activeClassName="any" exact to={props.link}>
      {props.children}
    </StyledNavLink>
  );
};
const StyledNavLink = styled(RouteNavLink)``;
