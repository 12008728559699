import { green, lightGreen } from "@material-ui/core/colors";
import React from "react";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { HandleLogOut } from "../../helperFunctions/logOut";
import styled from "styled-components";
import { fontFamily } from "../../globalAssets/fontFamily";
import { device } from "../../globalAssets/mediaQueries";
import { darkgreen, grey2, lightgreen1, white } from "../../globalAssets/theme";
import {} from "../../globalAssets/theme";

import {
  SmallLogo,
  DashboardIcon,
  WalletIcon,
  LandScapeIcon,
  ProfileIcon,
  LogoutIcon,
  HistoryIcon,
} from "../../globalComponent/Index";

export const SideNav = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const handleLogOut = () => {
    history.push("/");
    localStorage.removeItem("token");
  };

  const sideNavLinks = [
    { text: "Dashboard", link: `${path}`, icon: <DashboardIcon /> },
    { text: "My Wallet", link: `${path}/wallet`, icon: <WalletIcon /> },
    { text: "Surveys", link: `${path}/survey`, icon: <LandScapeIcon /> },
    { text: "My Profile", link: `${path}/profile`, icon: <ProfileIcon /> },
    {
      text: "Survey History",
      link: `${path}/surveyhistory`,
      icon: <HistoryIcon />,
    },
    // { text: "Logout", icon: <ILogout /> },
  ];
  // console.log("the link", sideNavLinks);
  const navLink = sideNavLinks.map((link, index) => (
    <StyledNavLink key={index} exact to={link.link}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {link.icon}
        {link.text}
      </div>
    </StyledNavLink>
  ));
  return (
    <NavContainer>
      <SmallLogo />
      <LinkContainer>
        {navLink}
        <StyledNavLink style={{ color: "red" }} isActive={false} to="/">
          <div
            onClick={() => handleLogOut()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <LogoutIcon />
            Logout
          </div>
        </StyledNavLink>
      </LinkContainer>
    </NavContainer>
  );
};
const NavContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    width: 17vw;
    position: sticky;
    z-index: 300;
    top: 0;
    left: 0;
    text-align: center;
    height: 100vh;
    background: ${white};
    ${"" /* background: red; */}
    box-shadow:0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    padding: 1.3vw 0.5vw;
    font-size: 1.3vw;
    display: block;
  }
`;
const LinkContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    margin-top: 1.3vw;
    display: block;
    padding: 0 1.5vw;
  }
`;
const StyledNavLink = styled(NavLink)`
  color: black;
  margin: 1.62vw 0.58vw;
  display: block;
  text-align: start;
  font-family: ${fontFamily.Sora};
  font-size: 0.9vw;
  color: grey;
  &.active {
    transition: all 0.7s;
    color: ${lightgreen1};
    font-weight: bold;
  }
  &:hover {
    color: ${lightgreen1};
    transition: all 1s;
  }
`;
