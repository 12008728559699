import React from "react";
import styled from "styled-components";

export const Image = (props) => {
  return <StyledImage src={props.src} />;
};

export const UserProfileImage = (props) => {
  return <StyledUserImage src={props.src} />;
};

const StyledImage = styled.img`
  height: 60px;
  width: 60px;
  border: none;
  outline: none;
  margin: 0;
  border-radius: 100%;
`;

const StyledUserImage = styled.img`
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border: none;
  outline: none;
  border-radius: 100%;
  margin: 0 0.65vw;
  height: 2.7vw;
  width: 2.7vw;
  ${"" /* width: */}
`;
//https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80
