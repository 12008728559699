import React from 'react';
import { FaWallet } from "react-icons/fa";

import styled from "styled-components";
import { black } from "../globalAssets/theme";
export const IWallet = () => {
  return <StylledWallet />;
};

const StylledWallet = styled(FaWallet)`
  font-size: 20px;
  padding: 2px;
  color: ${black};
`;
export const WalletIcon = () => {
  return <StyleWalletIcon />;
};
const StyleWalletIcon = styled(FaWallet)`
  font-size: 1vw;
  margin: 0 10px 0 0;
`;
