import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  HeroContainer,
  Form,
  FormItem,
  TextInput,
  LoggerButton,
  IMail,
  LTitle,
  Spinner,
  MessageNotificationError,
  MessageNotificationSuccess,
} from "../globalComponent/Index";
import {
  requestForgotPassword,
  clearNotification,
} from "../redux-toolkit/forgotPassword";
import { closeModal } from "../redux-toolkit/drawerSlice/forgotModalSlice";
/* import Alert from '@material-ui/lab/Alert'; */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const ForgotPasswordModal = (props) => {
  const classes = useStyles();
  const dispatcher = useDispatch();
  const modalValue = useSelector((state) => state.modalValue.visible);
  const updateNotification = useSelector(
    (state) => state.forgotPassword.updateNotification
  );
  const message = useSelector((state) => state.forgotPassword.data);
  const notification = useSelector(
    (state) => state.forgotPassword.updateNotification
  );
  // console.log("forgotPassword", message);

  const onFinish = (values) => {
    // console.log("success:", values);
    dispatcher(requestForgotPassword(values));
  };

  React.useEffect(() => {
    if (updateNotification === "success") {
      MessageNotificationSuccess(message);
      dispatcher(clearNotification());
      dispatcher(closeModal());
    }
    if (updateNotification === "error") {
      MessageNotificationError("please check email and retry");
      dispatcher(clearNotification());
      dispatcher(closeModal());
    }
  }, [updateNotification]);

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Dialog
      open={modalValue}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      isableEscapeKeyDown={true}
    >
      {/* <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <HeroContainer>
            <LTitle title="Forgot Password" />

            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input Email",
                  },
                  {
                    type: "email",
                    message: "The format is not an email format",
                  },
                ]}
              >
                <TextInput icon={<IMail />} />
              </FormItem>

              {notification === "loading" ? (
                <Spinner />
              ) : (
                <LoggerButton htmlType="submit" text="LOGIN" />
              )}
            </Form>
          </HeroContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => dispatcher(closeModal())}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
