import React from "react";

export const UserPolicy = (props) => {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
      <p>
        {/* <h1></h1> */}
        <h5>
          Last updated: March 26, 2021 Please read these terms and conditions
          carefully before using Our Services.
        </h5>
        <h1>Interpretation and Definitions</h1>
        <h2>Interpretation</h2>
        <small>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
          <br />
          <br />
          <h2>Definitions</h2>
          For the purposes of these Terms and Conditions:
          <br /> • Account means a unique account created for You to access our
          Services or parts of our Services.
          <br />• Affiliate means an entity that controls, is controlled by or
          is under common control with a party, where "control" means ownership
          of 50% or more of the shares, equity interest or other securities
          entitled to vote for election of directors or other managing
          authority.
          <br />• Company (referred to as either "the Company", "We", "Us" or
          "Our" in this Agreement) refers to SURVEYPLUS LTD, 4 Babatope Bejide
          Street, Lekki Phase 1, Lagos.
          <br />• Cookies are small files that are placed on Your computer,
          mobile device or any other device by a website, containing the details
          of Your browsing history on that website among its many uses.
          <br />• Country refers to: Nigeria <br />• Device means any device
          that can access the Services such as a computer, a cellphone or a
          digital tablet. <br />• Personal Data is any information that relates
          to an identified or identifiable individual. <br />• Services refers
          SurveyPlus’ products, Services, websites and apps. <br />• Terms and
          Conditions (also referred as "Terms") mean these Terms and Conditions
          that form the entire agreement between You and the Company regarding
          the use of the Services. <br />• Third-party Social Media Services
          means any Services or content (including data, information, products
          or Services) provided by a third-party that may be displayed, included
          or made available by the Services. <br />• Usage Data refers to data
          collected automatically, either generated by the use of the Services
          or from the Services infrastructure itself (for example, the duration
          of a page visit).
          <br />• Website refers to SurveyPlus, accessible from
          http://www.getsurveyplus.com
          <br />• You means the individual accessing or using the Services, or
          the company, or other legal entity on behalf of which such individual
          is accessing or using the Services, as applicable.
          <br />
          <br />
          <h2>Acknowledgment</h2>
          These are the Terms and Conditions governing the use of these Services
          and the agreement that operates between You and the Company. These
          Terms and Conditions set out the rights and obligations of all users
          regarding the use of the Services.
          <br /> Your access to and use of the Services is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users and others who
          access or use the Services. Our Terms describes Our policies and
          procedures on the collection, use and disclosure of Your personal
          information when You use the Application or the Website and tells You
          about Your privacy rights and how the law protects You. Please read
          Our Terms carefully before using Our Services.
          <br /> By accessing or using the Services You agree to be bound by
          these Terms and Conditions. If You disagree with any part of these
          Terms and Conditions then You may not access the Services. <br />
          You represent that you are over the age of 18. The Company does not
          permit those under 18 to use the Services. <br />
          If you will be using the Services on behalf of an organization, you
          agree to these Terms and Conditions on behalf of that organization and
          you represent that you have the authority to do so. In such case,
          “you” and “your” will refer to that organization.
          <br />
          <br />
          <h2>Fees and Payment</h2>
          You agree to pay all applicable, undisputed fees for the Services on
          the terms set forth for the Services. Except set forth in the Service
          Description, any and all payments you make to us for access to the
          Services are final and non-refundable. You are responsible for
          providing accurate payment and contact information to us. You agree
          that your debit or credit card information and related personal may be
          provided to third parties for payment processing and fraud prevention
          purposes.
          <br /> You are responsible for all applicable sales, services,
          value-added, goods and services, withholding, tariffs and similar
          charges (collectively, “Taxes”) imposed by any government entity or
          collecting agency based on the Services, except those Taxes based on
          our net income, or Taxes for which you have provided an exemption
          certificate. Additionally, if you do not satisfy your Tax obligations,
          you agree that you will be required to reimburse us for any taxes paid
          on your behalf, and we may take steps to collect Taxes we have paid on
          your behalf. In all cases, you will pay the amounts due under this
          Terms to us in full without any right of set-off or deduction.
          <br />
          <br />
          <h2>Privacy</h2> <br /> Type of Data Collected
          <br />
          Personal Data: While using Our Services, We may ask You to provide Us
          with certain personally identifiable information that can be used to
          contact or identify You. Personally identifiable information may
          include, but is not limited to: email address, first name, last name,
          phone number, address, demographic data.
          <br /> Usage Data: Usage Data is collected automatically when using
          the Services. Usage Data may include information such as Your Device's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Services that You visit, the time and date
          of Your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
          <br /> When You access the Services by or through a mobile device, We
          may collect certain information automatically, including, but not
          limited to, the type of mobile device You use, Your mobile device
          unique ID, the IP address of Your mobile device, Your mobile operating
          system, the type of mobile Internet browser You use, unique device
          identifiers and other diagnostic data. We may also collect information
          that Your browser sends whenever You visit our Services or when You
          access the Services by or through a mobile device.
          <br />
          Tracking Technologies and Cookies: We use Cookies and similar tracking
          technologies to track the activity on Our Services and store certain
          information. Tracking technologies used are beacons, tags, and scripts
          to collect and track information and to improve and analyze Our
          Services.
          <br />
          Use of Your Personal Data The Company may use Personal Data for the
          following purposes:
          <br />• To provide and maintain our Services, including to monitor the
          usage of our Services. <br />• To manage Your Account: to manage Your
          registration as a user of the Services. The Personal Data You provide
          can give You access to different functionalities of the Services that
          are available to You as a registered user. <br />• For the performance
          of a contract: the development, compliance and undertaking of the
          purchase contract for the products, items or services You have
          purchased or of any other contract with Us through the Services.{" "}
          <br />• To contact You: To contact You by email, telephone calls, SMS,
          or other equivalent forms of electronic communication, such as a
          mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation. <br />• To provide You with news,
          special offers and general information about other goods, services and
          events which we offer that are similar to those that you have already
          purchased or enquired about unless You have opted not to receive such
          information. <br />• To manage Your requests: To attend and manage
          Your requests to Us. <br />• For business transfers: We may use Your
          information to evaluate or conduct a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Our assets, whether as a going concern or as part of
          bankruptcy, liquidation, or similar proceeding, in which Personal Data
          held by Us about our Services users is among the assets transferred.{" "}
          <br />• For other purposes: We may use Your information for other
          purposes, such as data analysis, identifying usage trends, determining
          the effectiveness of our promotional campaigns and to evaluate and
          improve our Services, products, services, marketing and your
          experience. We may share Your personal information in the following
          situations: <br />• With Service Providers:
          <br />
          We may share Your personal information with Services Providers to
          monitor and analyze the use of our Services, to contact You. <br />•
          For business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of Our business to another company.
          <br />• With Affiliates: We may share Your information with Our
          affiliates, in which case we will require those affiliates to honor
          these Terms. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us. <br />• With
          business partners: We may share Your information with Our business
          partners to offer You certain products, services or promotions. <br />
          • With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social
          Media Service, Your contacts on the Third-Party Social Media Services
          may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your
          activity, communicate with You and view Your profile. <br />• With
          Your consent: We may disclose Your personal information for any other
          purpose with Your consent.
          <br />
          <br />
          <h2>Retention of Your Personal Data The Company</h2>
          will retain Your Personal Data only for as long as is necessary for
          the purposes set out in these Terms. We will retain and use Your
          Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies. The Company will also retain Usage Data for
          internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strengthen
          the security or to improve the functionality of Our Services, or We
          are legally obligated to retain this data for longer time periods.
          <br />
          <br />
          <h2>Transfer of Your Personal Data</h2>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction. Your consent to these Terms followed by Your submission
          of such information represents Your agreement to that transfer. The
          Company will take all steps reasonably necessary to ensure that Your
          data is treated securely and in accordance with these Terms and no
          transfer of Your Personal Data will take place to an organization or a
          country unless there are adequate controls in place including the
          security of Your data and other personal information.
          <br />
          <br />
          <h2> Disclosure of Your Personal Data</h2>• Business Transactions: If
          the Company is involved in a merger, acquisition or asset sale, Your
          Personal Data may be transferred. We will provide notice before Your
          Personal Data is transferred and becomes subject to a different Terms.
          <br /> • Law enforcement: Under certain circumstances, the Company may
          be required to disclose Your Personal Data if required to do so by law
          or in response to valid requests by public authorities (e.g. a court
          or a government agency).
          <br /> • Other legal requirements: The Company may disclose Your
          Personal Data in the good faith belief that such action is necessary
          to: o Comply with a legal obligation <br />o Protect and defend the
          rights or property of the Company <br />o Prevent or investigate
          possible wrongdoing in connection with the Service <br />o Protect the
          personal safety of Users of the Service or the public
          <br /> o Protect against legal liability
          <br />
          <br />
          <h2> Security of Your Personal</h2>
          Data The security of Your Personal Data is important to Us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While We strive to use
          commercially acceptable means to protect Your Personal Data, We cannot
          guarantee its absolute security.
          <br />
          <br />
          <h1>User-Generated Content</h1>
          The Services may contain information, text, links, graphics, photos,
          videos, or other materials (“Content”), including Content created with
          or submitted to the Services by you or through your Account (“Your
          Content”). We take no responsibility for and we do not expressly or
          implicitly endorse any of Your Content.
          <br /> You retain any ownership rights you have in Your Content, but
          you grant us the following license to use that Content:
          <br /> When Your Content is created with or submitted to the Services,
          you grant us a worldwide, royalty-free, perpetual, irrevocable,
          non-exclusive, transferrable, and sublicensable license to use, copy,
          modify, adapt, prepare derivative works from, distribute, and
          otherwise exploit your Content, but only for the limited purposes of
          providing the Services to you and as otherwise permitted by these
          Terms. <br />
          This license also extends to any trusted third parties we work with to
          the extent necessary to provide the Services to you.
          <br /> Any ideas, suggestions, and feedback about SurveyPlus or our
          Services that you provide to us are entirely voluntary, and you agree
          that SurveyPlus may use such ideas, suggestions, and feedback without
          compensation or obligation to you.
          <br /> Although we have no obligation to screen, edit or monitor Your
          Content, we may, in our sole discretion, delete or remove Your Content
          at any time and for any reason, including for a violation of these
          Terms, or if you otherwise create liability for us.
          <br />
          <br />
          <h1>
            {" "}
            Links to Other Websites
            <br />
          </h1>{" "}
          Our Services may contain links to third-party web sites or Services
          that are not owned or controlled by the Company.
          <br /> The Company has no control over, and assumes no responsibility
          for, the content, privacy policies, or practices of any third party
          web sites or Services. You further acknowledge and agree that the
          Company shall not be responsible or liable, directly or indirectly,
          for any damage or loss caused or alleged to be caused by or in
          connection with the use of or reliance on any such content, goods or
          Services available on or through any such web sites or Services.
          <br /> We strongly advise You to read the terms and conditions and
          privacy policies of any third-party web sites or Services that You
          visit.
          <br />
          <br />
          <h1>Termination</h1> We may terminate or suspend Your access
          immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if You breach these Terms.
          Upon termination, Your right to use the Services will cease
          immediately. <br />
          <br />
          <h1>Limitation of Liability</h1> Notwithstanding any damages that You
          might incur, the entire liability of the Company and any of its
          suppliers under any provision of these Terms and Your exclusive remedy
          for all of the foregoing shall be limited to the amount actually paid
          by You through the Services. To the maximum extent permitted by
          applicable law, in no event shall the Company or its suppliers be
          liable for any special, incidental, indirect, or consequential damages
          whatsoever (including, but not limited to, damages for loss of
          profits, loss of data or other information, for business interruption,
          for personal injury, loss of privacy arising out of or in any way
          related to the use of or inability to use the Services, third-party
          software and/or third-party hardware used with the Services, or
          otherwise in connection with any provision of these Terms), even if
          the Company or any supplier has been advised of the possibility of
          such damages and even if the remedy fails of its essential purpose.
          <br />
          <br />
          <h1> "AS IS" and "AS AVAILABLE" Disclaimer</h1>
          The Services is provided to You "AS IS" and "AS AVAILABLE" and with
          all faults and defects without warranty of any kind. To the maximum
          extent permitted under applicable law, the Company, on its own behalf
          and on behalf of its Affiliates and its and their respective licensors
          and Services providers, expressly disclaims all warranties, whether
          express, implied, statutory or otherwise, with respect to the
          Services, including all implied warranties of merchantability, fitness
          for a particular purpose, title and non-infringement, and warranties
          that may arise out of course of dealing, course of performance, usage
          or trade practice. Without limitation to the foregoing, the Company
          provides no warranty or undertaking, and makes no representation of
          any kind that the Services will meet Your requirements, achieve any
          intended results, be compatible or work with any other software,
          applications, systems or Services, operate without interruption, meet
          any performance or reliability standards or be error free or that any
          errors or defects can or will be corrected.
          <br /> Without limiting the foregoing, neither the Company nor any of
          the company's provider makes any representation or warranty of any
          kind, express or implied: (i) as to the operation or availability of
          the Services, or the information, content, and materials or products
          included thereon; (ii) that the Services will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the Services; or (iv) that the
          Services, its servers, the content, or e-mails sent from or on behalf
          of the Company are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components.
          <br />
          <br />
          <h1>Governing Law</h1> The laws of the Country, excluding its
          conflicts of law rules, shall govern these Terms and Your use of the
          Services. All parties submit to the non-exclusive jurisdiction of the
          courts of this Country.
          <br />
          <br />
          <h1>Disputes Resolution</h1>
          If You have any concern or dispute about the Services, You agree to
          first try to resolve the dispute informally by contacting the Company.
          <br />
          <h1>Severability and Waiver</h1>
          <h2>Severability</h2>
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
          <br />
          <br />
          <h2>Waiver</h2> Except as provided herein, the failure to exercise a
          right or to require performance of an obligation under these Terms
          shall not effect a party's ability to exercise such right or require
          such performance at any time thereafter nor shall be the waiver of a
          breach constitute a waiver of any subsequent breach.
          <br />
          <br />
          <h2>Translation</h2>
          Interpretation These Terms and Conditions may have been translated if
          We have made them available to You on our Services. You agree that the
          original English text shall prevail in the case of a dispute.
          <br />
          <br />
          <h2>Changes to These Terms and Conditions</h2>
          We reserve the right, at Our sole discretion, to modify or replace
          these Terms at any time. If a revision is material We will make
          reasonable efforts to provide at least 30 days' notice prior to any
          new terms taking effect. What constitutes a material change will be
          determined at Our sole discretion. <br />
          By continuing to access or use Our Services after those revisions
          become effective, You agree to be bound by the revised terms. If You
          do not agree to the new terms, in whole or in part, please stop using
          the website and the Services.
          <br />
          <br />
          <h2>Contact Us</h2> If you have any questions about these Terms and
          Conditions, You can contact us:
          <br />• By email: info@getsurveyplus.com
        </small>
      </p>
    </div>
  );
};
