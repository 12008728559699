import React from 'react';
import Button from '@material-ui/core/Button';
import { Select } from 'antd';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Option } from './BankDropDown';
import {
  HeroContainer,
  Form,
  FormItem,
  TextInput,
  LoggerButton,
  LTitle,
  Spinner,
  MessageNotificationError,
  MessageNotificationSuccess,
} from '../globalComponent/Index';
import {
  closeModal,
  clearNotification,
  addAccount,
} from '../redux-toolkit/AddAccountSlice';
/* import Alert from '@material-ui/lab/Alert'; */
import { makeStyles } from '@material-ui/core/styles';
import { fetchUserInfo } from "../redux-toolkit/fetchUserInfoSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      // marginTop: theme.,
    },
  },
}));

export const bankArray = [
  { bankName: 'Access Bank Plc', id: '044' },
  { bankName: 'Fidelity Bank Plc', id: '070' },
  { bankName: 'First City Monument Bank Limited', id: '214' },
  { bankName: 'First Bank of Nigeria Limited', id: '011' },
  { bankName: 'Guaranty Trust Bank Plc', id: '058' },
  { bankName: 'Union Bank of Nigeria Plc', id: '032' },
  { bankName: 'United Bank for Africa Plc', id: '033' },
  { bankName: 'Zenith Bank Plc', id: '057' },
  { bankName: 'Ecobank Nigeria Limited', id: '050' },
  { bankName: 'Keystone Bank Limited', id: '082' },
  { bankName: 'Stanbic IBTC Bank Plc', id: '039' },
  { bankName: 'Sterling Bank Plc', id: '232' },
  { bankName: 'Unity Bank Plc', id: '215' },
  { bankName: 'Wema Bank Plc', id: '035' },
  { bankName: 'Jaiz Bank Plc', id: '301' },
  { bankName: 'Providus Bank', id: '101' },
  { bankName: 'SunTrust Bank Nigeria Limited', id: '100' },
  { bankName: 'Standard Chartered', id: '068' },
  { bankName: 'Polaris Bank Limited', id: '076' },
];

/* const bankArray = [
  {
    id: '044',
    bankName: "Access Bank Plc",
  },
  {
    id: '070',
    bankName: "Fidelity Bank Plc",
  },
  {
    id: '214',
    bankName: "First City Monument Bank Limited",
  },
  {
    id: 4,
    bankName: "First Bank of Nigeria Limited",
  },
  {
    id: 5,
    bankName: "Guaranty Trust Bank Plc",
  },
  {
    id: 6,
    bankName: "Union Bank of Nigeria Plc",
  },
  {
    id: 7,
    bankName: "United Bank for Africa Plc",
  },
  {
    id: 8,
    bankName: "Zenith Bank Plc",
  },
  {
    id: 9,
    bankName: "Citi Bank Nigeria Limited",
  },
  {
    id: 10,
    bankName: "Ecobank Nigeria Plc",
  },
  {
    id: 11,
    bankName: "Heritage Banking Company Limited",
  },
  {
    id: 12,
    bankName: "Keystone Bank Limited",
  },
  {
    id: 13,
    bankName: "Polaris Bank Limited",
  },
  {
    id: 14,
    bankName: "Stanbic IBTC Bank Plc",
  },
  {
    id: 15,
    bankName: "Standard Chartered",
  },
  {
    id: 16,
    bankName: "Sterling Bank Plc",
  },
  {
    id: 17,
    bankName: "Unity Bank Plc",
  },
  {
    id: 18,
    bankName: "Wema Bank Plc",
  },
  {
    id: 19,
    bankName: "Providus Bank Limited",
  },
  {
    id: 20,
    bankName: "Jaiz Bank Plc",
  },
  {
    id: 21,
    bankName: "SunTrust Bank Nigeria Limited",
  },
]; */
export const AddAccount = (props) => {
  const classes = useStyles();
  const dispatcher = useDispatch();
  const visible = useSelector((state) => state.addAccount.visible);
  const notification = useSelector((state) => state.addAccount.notification);
  const message = useSelector((state) => state.addAccount.message);
  const onFinish = (values) => {
    dispatcher(addAccount(values));
  };

  React.useEffect(() => {
    if (notification === 'success') {
      MessageNotificationSuccess(message);
      dispatcher(clearNotification());
      dispatcher(closeModal());
      dispatcher(fetchUserInfo())
    }
    if (notification === 'error') {
      MessageNotificationError('Please try again!');
      dispatcher(clearNotification());
      dispatcher(closeModal());
    }
  }, [notification]);

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Dialog
      style={{ paddingTop: '-10px' }}
      open={visible}
      // aria-labelledby="alert-dialog-title"
      aria-describedby='alert-dialog-description'
      disableBackdropClick={true}
      isableEscapeKeyDown={true}
    >
      {/* <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle> */}
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <HeroContainer>
            <LTitle title='Account Details' />

            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <FormItem
                label='Bank Name'
                name='bank_name'
                rules={[
                  {
                    required: true,
                    message: 'Please select bank',
                  },
                ]}
              >
                <Dropdown>
                  <Option />
                  {bankArray.map((values) => (
                    <Option key={values.key} value={values.bankName}>
                      {values.bankName}
                    </Option>
                  ))}
                </Dropdown>
              </FormItem>
              <FormItem
                label='Account Name'
                name='acct_name'
                rules={[
                  {
                    required: true,
                    message: 'Please input Account Name',
                  },
                ]}
              >
                <TextInput />
              </FormItem>
              <FormItem
                label='Account Number'
                name='acct_num'
                rules={[
                  {
                    required: true,
                    message: 'Please input Account Number',
                  },
                ]}
              >
                <TextInput type='number' />
              </FormItem>

              {notification === 'loading' ? (
                <Spinner />
              ) : (
                <LoggerButton htmlType='submit' text='Add Bank' />
              )}
            </Form>
          </HeroContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size='small'
          onClick={() => dispatcher(closeModal())}
          color='primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
