import React from 'react';
import { CgProfile } from "react-icons/cg";

import styled from "styled-components";
import { black } from "../globalAssets/theme";
export const IProfile = () => {
  return <StyledProfile />;
};

const StyledProfile = styled(CgProfile)`
  font-size: 20px;
  ${"" /* padding: 2px; */}
  color: ${black};
`;

export const ProfileIcon = () => {
  return <StyleProfile />;
};
const StyleProfile = styled(CgProfile)`
  font-size: 1.1vw;
  margin: 0 10px 0 0;
`;
