export const userLoginRoute = "/api-aut-login";
export const userRegistrationRoute = "/api/register-user";
export const fetchUserInfoRoute = "/api/user";
export const updateLocationRoute = "/api/add-location";
export const updateSettlementRoute = "/api/add-settlemet";
export const updateMonthlyIncomeRoute = "/api/add-montly_income";
export const updateGenderRoute = "/api/add-gender";
export const updateMaritalStatusRoute = "/api/add-marrital_status";
export const updateHouseHoldRoute = "/api/add-household";
export const updateDOBRoute = "/api/add-date_of_birth";
export const updateEducationRoute = "/api/add-education";
export const updateIncomeSourceRoute = "/api/add_income";
export const updatePhoneOwnershipRoute = "/api/add-mobile_ownership";
export const fetchSurveysRoute = "/api/my-surveys?page=";
export const fetchSingleSurveyRoute = "/api/view-surveys?survey_id=";
export const submitMainResponseRoute = "/api/submit-responds";
export const submitScreeningResponseRoute = "/api/submit-screening_question";
export const participantSurveyHistoryRoute = "/api/user_survey_history?page=";
export const forgotPasswordRoute = "/api/forgot-password";
export const updateAccountDetailsRoute = "/api/add-account";
export const resendVerificationLinkRoute = "/api/resend-email-verification";
export const updateProfilePicRoute = "/api/add-profilepic";
export const buyAirtime = '/api/init-withdrawal/airtime';
export const transactionHistory = '/api/transactions';
export const transferToBank = '/api/init-withdrawal/bank';
