import styled from "styled-components";
import { fontFamily } from "../../globalAssets/fontFamily";
import {
  black,
  darkgreen,
  activeShadow2,
  grey1,
  grey2,
  activeShadow,
  activeShadow3,
  lightgreen,
} from "../../globalAssets/theme";

export const Date = styled.h5`
  font-family: ${fontFamily.Sora};
  color: ${black};
  line-height: 5px;
  text-align: left;
  font-size: 10px;
`;
export const SurveyContainer = styled.div`
  width: 100%;
  ${"" /* height: 30px; */}
  ${"" /* box-shadow: 0px 0px px ${activeShadow2}; */}
    box-shadow:0px 3px 8px -1px rgba(50, 50, 71, 0.05);

  display: flex;
  margin-bottom: 20px;
`;
export const SurveyTitle = styled.div`
  padding: 10px;
  height: 100%;
  width: 60%;
  text-align: left;
  font-size: 16px;
  color: ${black};
  font-family: ${fontFamily.Sora};
  border-right: 1px solid ${activeShadow3};
`;
export const SurveyNumber = styled.div`
  padding: 10px;
  text-align: right;
  font-style: italic;
  height: 100%;
  width: 40%;
  border: none;
  outline: none;
  color: ${lightgreen};
`;
