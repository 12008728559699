import React from 'react';
import { RiMapPinTimeLine } from "react-icons/ri";
import styled from "styled-components";
import { white } from "../globalAssets/theme";
export const LifeTimeIcon = () => {
  return <StyleLifeTimeIcon />;
};

const StyleLifeTimeIcon = styled(RiMapPinTimeLine)`
  font-size: 2vw;
  color: ${white};
`;
