import React from "react";
import Button from "@material-ui/core/Button";
import { Select } from "antd";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Option } from "./BankDropDown";
import {
  HeroContainer,
  Form,
  FormItem,
  TextInput,
  LoggerButton,
  LTitle,
  Spinner,
  MessageNotificationError,
  MessageNotificationSuccess,
} from "../globalComponent/Index";

/* import Alert from '@material-ui/lab/Alert'; */
import { makeStyles } from "@material-ui/core/styles";
import { SnackBar } from './SnackBar';
import { fetchUserInfo } from "../redux-toolkit/fetchUserInfoSlice";
import {
  toggleModal,
  clearNotification,
  transcferToBank,
  getTransctonHistory
} from '../redux-toolkit/transactionSlice';
import { bankArray } from './AddAccount';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      // marginTop: theme.,
    },
  },
}));

export const BankTransferModal = (props) => {
  const dispatcher = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const { visible, notification, message, error } = useSelector(
    (state) => state.transactions,
  );

  const onFinish = (values) => {
    
    const bankCode = bankArray.find(item =>  item.bankName === userInfo.bank_name)['id'];
    const data = {
      bank_code : bankCode,
      amount: values.amount,
      account_number: userInfo.account_num
    }

    console.log({ data })
    dispatcher(transcferToBank(data));

  };

  React.useEffect(() => {
    if (notification === 'success') {
     dispatcher(toggleModal(''));
     dispatcher(getTransctonHistory());
     dispatcher(fetchUserInfo())
   }
 }, [notification]);

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <>
    <Dialog
      style={{ paddingTop: "-10px" }}
      open={visible === 'bankTransfer'}
      // aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      isableEscapeKeyDown={true}
    >
      {/* <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <HeroContainer>
            <LTitle title="Transfer to Bank" />

            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            
              <FormItem
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please input Account Name",
                  },
                ]}
              >
                <TextInput />
              </FormItem>
              {notification === "loading" ? (
                <Spinner />
              ) : (
                <LoggerButton full htmlType="submit" text="Add Bank" />
              )}
            </Form>
          </HeroContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => dispatcher(toggleModal(''))}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    <SnackBar
    open={notification === 'error'}
    type='error'
    message={error}
    onClose={() => {
      //dispatcher(toggleModal(''));
      dispatcher(clearNotification());
    }}
  />
  <SnackBar
    open={notification === 'success'}
    type='success'
    message={message}
    onClose={() => {
      dispatcher(clearNotification());
    }}
  />
  </>
  );
};
