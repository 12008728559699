import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { ContentContainer } from "./style";
import "../../index.css";
import {
  CompoenentContainer,
  MobileHeader,
  IBackArrow,
  LaptopHeader,
  Notification,
  MessageNotificationError,
  MessageNotificationSuccessShort,
  IBackArrowBlack,
} from "../../globalComponent/Index";
import styled from "styled-components";
import {
  UpdateLocationComponent,
  UpdateSettlementComponent,
  UpdateMaritalStatusComponent,
  UpdateHouseHoldComponent,
  UpdateGenderComponent,
  UpdateDOBComponent,
  UpdateEducationLevelComponent,
  UpdateIncomeSourceComponent,
  UpdateMobileOwnershipComponent,
  UpdateMonthlyIncomeComponent,
} from "./StepContent";
import {
  clearNotification,
  updateLocation,
  updateSettlement,
  updateMaritalStatus,
  updateHouseHold,
  updateGender,
  updateDOB,
  updateEducation,
  updateIncomeSource,
  updatePhoneOwnership,
  updatedMonthlyIncome,
} from "../../redux-toolkit/updateProfileSlice";
import { device } from "../../globalAssets/mediaQueries";

const UpdateProfile = () => {
  const updateImageNotification = useSelector(
    (state) => state.updateImage.notification
  );
  const dispatcher = useDispatch();
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const updateNotification = useSelector(
    (state) => state.updateProfile.updateNotification
  );
  const updateState = useSelector((state) => state.updateProfile.updateStatus);
  // console.log("the notification from update profile is", updateNotification);

  React.useEffect(() => {
    if (updateNotification === "success") {
      // Notification("success", "Updated!", `${updateState}`);
      MessageNotificationSuccessShort(updateState);
    }
    if (updateNotification === "error") {
      // Notification("error", "Not Uploaded", `${updateState}`);
      MessageNotificationError(updateState);
    }
    dispatcher(clearNotification());

    // dispatcher(clearNotification());
  }, [updateNotification]);

  const green = true;
  const { Step } = Steps;
  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          {/* step {index} status: {status} */}
          STEP {index + 1}
        </span>
      }
    >
      {dot}
    </Popover>
  );
  const steps = [
    {
      question: "What is your location?",
      id: "location",
      content: (
        <UpdateLocationComponent
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateLocation(values), next())}
        />
      ),
    },
    {
      question: "Gender",
      id: "gender",
      content: (
        <UpdateGenderComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateGender(values), next())}
        />
      ),
    },
    {
      question: "What is your Settlement type ?",
      id: "settlement",
      content: (
        <UpdateSettlementComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateSettlement(values), next())}
        />
      ),
    },
    {
      question: "Which of the following best describes your marital status?",
      id: "marital status",
      content: (
        <UpdateMaritalStatusComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateMaritalStatus(values), next())}
        />
      ),
    },
    {
      question: "What is your relationship with head of the household?",
      id: "house hold",
      content: (
        <UpdateHouseHoldComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateHouseHold(values), next())}
        />
      ),
    },

    {
      question: "Date of Birth",
      id: "date_of_birth",
      content: (
        <UpdateDOBComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateDOB(values), next())}
        />
      ),
    },
    {
      question: "Level of Education",
      id: "education",
      content: (
        <UpdateEducationLevelComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateEducation(values), next())}
        />
      ),
    },
    {
      question: "Main Source of Income",
      id: "sourceincome",
      content: (
        <UpdateIncomeSourceComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updateIncomeSource(values), next())}
        />
      ),
    },
    {
      question: "Mobile Phone Ownership",
      id: "phone ownership",
      content: (
        <UpdateMobileOwnershipComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) => dispatcher(updatePhoneOwnership(values), next())}
        />
      ),
    },

    {
      question: "Monthly Income Level",
      id: "monthly income",
      content: (
        <UpdateMonthlyIncomeComponent
          backText={"Back"}
          backFunction={() => prev()}
          buttonText={"Next"}
          onClick={(values) =>
            dispatcher(
              updatedMonthlyIncome(values),
              history.replace("/dashboard")
            )
          }
        />
      ),
    },
  ];
  const StepsVariable = (
    <div>
      <Steps
        size="small"
        direction="horizontal"
        style={{
          paddingTop: "10px",
          marginLeft: "-3.250vw",
          // boxSizing: "border-box",
        }}
        progressDot={customDot}
        responsive
        current={current}
      >
        {steps.map((item) => (
          <Step
            size="small"
            key={item.id}
            // title={item.question}
          />
        ))}
      </Steps>
    </div>
  );

  return (
    <ComponentContainer>
      <IBackArrow changeColor={green} />
      <MobileHeader changeColor={green} title="Update Personal data" />
      <LaptopHeader title="Update Personal data" />
  {/*     <Container> */}
      {/*   {StepsVariable} */}
        <ContentContainer>
          <h4>{steps[current].question}</h4>

          {steps[current].content}
        </ContentContainer>
   {/*    </Container> */}
    </ComponentContainer>
  );
};

export default UpdateProfile;

const ComponentContainer = styled.div`
  position: relative;
  width: 100%;
  ${"" /* padding:0 10px */}
  margin-bottom: 40px;
`;

const Container = styled.div`
 /*  display: flex;
  justify-content: center;
  text-align:left;
  @media ${device.laptop} {
    display: block;
    max-width:13vw;
    text-align: center;
    margin-left:10px
  } */
`;

//   {
//     'question': 'Settlement type',
//     'type': 'radio group',
//     'key': 'settlementType',
//     'id': 'settlement',
//     'route': rAddSettlement,
//     'options': ['Urban', 'Rural']
//   },
//   {
//     'question': 'Which of the following best describes your marital status?',
//     'type': 'radio group',
//     'key': 'maritalStatus',
//     'id': 'marrital_status',
//     'route': rAddMarritalStatus,
//     'options': [
//       'Married (Monogamy)',
//       'Married (Polygamy)',
//       'Co-habiting/Living together',
//       'Divorced',
//       'Separated',
//       'Widowed',
//       'Never married'
//     ]
//   },
//   {
//     'question': 'What is your relationship with the head of the household?',
//     'key': 'relationshipWithHouseHead',
//     'id': 'household',
//     'route': rAddHousehold,
//     'type': 'radio group',
//     'options': [
//       'You are the head of the household',
//       'Spouse/partner',
//       'Parent/parent-in-law',
//       'Grandparent (or spouse's grandparent)',
//       'Child/adopted child/stepchild',
//       'Son/daughter-in-law',
//       'Other relative'
//     ]
//   },
//   {
//     'question': 'Gender',
//     'type': 'radio group',
//     'key': 'gender',
//     'id': 'gender',
//     'route': rAddGender,
//     'options': [
//       'Male',
//       'Female',
//     ]
//   },
//   {
//     'question': 'Date of Birth',
//     'type': 'date picker',
//     'id': 'date_of_birth',
//     'route': rAddDOB,
//   },
//   {
//     'question': 'Level of education',
//     'type': 'radio group',
//     'id': 'education',
//     'key': 'education',
//     'route': rAddEducation,
//     'options': [
//       'Pre-school',
//       'Primary incomplete',
//       'Primary complete',
//       'Secondary incomplete',
//       'Secondary complete',
//       'University/Polytechnic Undergraduate',
//       'University/Polytechnic OND complete',
//       'University/Polytechnic HND complete',
//       'Post-university incomplete',
//       'Post-university complete',
//       'Non-formal education (e.g. Arabic/Quranic education)',
//       'No education'
//     ]
//   },
//   {
//     'question': 'Main Source of Money/Income',
//     'type': 'radio group',
//     'key': 'incomeSource',
//     'id': 'income',
//     'route': rAddIncomeSource,
//     'options': [
//       'Salary/wages from Government',
//       'Salary/wages from business/company (formal sector)',
//       'Salary/wages from individuals (informal sector)',
//       'Subsistence/small scale farmer',
//       'Commercial/large scale farmer',
//       'Own business/trader – non-farming',
//       'Own business/trader – farming',
//       'Own business – provide a service (e.g. hairdresser, tailor, mechanic)',
//       'Rent/pension/returns on investment',
//       'Get money/supplies from family/friends',
//     ]
//   },
//   {
//     'question': 'Mobile Phone Ownership',
//     'type': 'radio group',
//     'key': 'phoneOwnerShip',
//     'id': 'mobile_ownership',
//     'route': rAddMobileOwnership,
//     'options': [
//       'Does not own mobile phone',
//       'Own basic phone',
//       'Own smartphone',
//     ]
//   },
//   {
//     'question': 'Monthly Income Level',
//     'type': 'radio group',
//     'key': 'incomeLevel',
//     'id': 'montly_income',
//     'route': rAddMonthlyIncome,
//     'options': [
//       'Not currently working, someone else pays my bills',
//       'Earn less than N30,000 monthly',
//       'Earn between N30,001 and N100,000 monthly',
//       'Earn above N100,000 monthly'
//     ]
//   },
// ];
