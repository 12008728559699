import React from 'react';
import { Drawer as AntDrawer } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "../globalAssets/mediaQueries";

export const Drawer = (props) => {
  const drawerVisible = useSelector((state) => state.drawer.visible);
  // console.log(drawerVisible);
  return (
    <StyledDrawer
      bodyStyle={{ padding: "0px" }}
      closable={false}
      onClose={props.onClose}
      visible={drawerVisible}
      placement="left"
      key="left"
    >
      {props.children}
    </StyledDrawer>
  );
};
const StyledDrawer = styled(AntDrawer)`
  @media ${device.laptop} {
    display: none;
  }
`;
