import React from 'react';
import Button from '@material-ui/core/Button';
import { Select } from 'antd';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Option } from './BankDropDown';
import {
  HeroContainer,
  Form,
  FormItem,
  TextInput,
  LoggerButton,
  LTitle,
  Spinner,
  MessageNotificationError,
  MessageNotificationSuccess,
} from '../globalComponent/Index';
import {
  toggleModal,
  clearNotification,
  buyAirtime,
  getTransctonHistory
} from '../redux-toolkit/transactionSlice';
/* import Alert from '@material-ui/lab/Alert'; */
import { makeStyles } from '@material-ui/core/styles';
import { SnackBar } from './SnackBar';
import { fetchUserInfo } from "../redux-toolkit/fetchUserInfoSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      // marginTop: theme.,
    },
  },
}));
const bankArray = [
  {
    id: 1,
    bankName: 'Access Bank Plc',
  },
  {
    id: 2,
    bankName: 'Fidelity Bank Plc',
  },
  {
    id: 3,
    bankName: 'First City Monument Bank Plc',
  },
  {
    id: 4,
    bankName: 'First Bank of Nigeria Limited',
  },
  {
    id: 5,
    bankName: 'Guaranty Trust Bank Plc',
  },
  {
    id: 6,
    bankName: 'Union Bank of Nigeria Plc',
  },
  {
    id: 7,
    bankName: 'United Bank for Africa Plc',
  },
  {
    id: 8,
    bankName: 'Zenith Bank Plc',
  },
  {
    id: 9,
    bankName: 'Citi Bank Nigeria Limited',
  },
  {
    id: 10,
    bankName: 'Ecobank Nigeria Plc',
  },
  {
    id: 11,
    bankName: 'Heritage Banking Company Limited',
  },
  {
    id: 12,
    bankName: 'Keystone Bank Limited',
  },
  {
    id: 13,
    bankName: 'Polaris Bank Limited',
  },
  {
    id: 14,
    bankName: 'Stanbic IBTC Bank Plc',
  },
  {
    id: 15,
    bankName: 'Standard Chartered',
  },
  {
    id: 16,
    bankName: 'Sterling Bank Plc',
  },
  {
    id: 17,
    bankName: 'Unity Bank Plc',
  },
  {
    id: 18,
    bankName: 'Wema Bank Plc',
  },
  {
    id: 19,
    bankName: 'Providus Bank Limited',
  },
  {
    id: 20,
    bankName: 'Jaiz Bank Plc',
  },
  {
    id: 21,
    bankName: 'SunTrust Bank Nigeria Limited',
  },
];
export const BuyAirtimeModal = (props) => {
  const dispatcher = useDispatch();
  const { visible, notification, message, error } = useSelector(
    (state) => state.transactions,
  );

  const onFinish = (values) => {

    if (values.phone_number.length > 10) {
      values.phone_number = values.phone_number.substring(
        values.phone_number.length - 10,
      );
    }

    dispatcher(
      buyAirtime({ ...values, phone_number: `+234${values.phone_number}` }),
    );
  };

  React.useEffect(() => {
     if (notification === 'success') {
      dispatcher(toggleModal(''));
      dispatcher(getTransctonHistory());
      dispatcher(fetchUserInfo())
    }
  }, [notification]);

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };

  console.log({ notification });
  return (
    <>
      <Dialog
        style={{ paddingTop: '-10px' }}
        open={visible === 'buyAirtime'}
        // aria-labelledby="alert-dialog-title"
        aria-describedby='alert-dialog-description'
        disableBackdropClick={true}
        isableEscapeKeyDown={true}
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <HeroContainer>
              <LTitle title='Buy Airtime' />

              <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <FormItem
                  label='Amount'
                  name='amount'
                  rules={[
                    {
                      required: true,
                      message: 'Please input an amount',
                    },
                  ]}
                >
                  <TextInput type='number' />
                </FormItem>
                <FormItem
                  label='Phone Number'
                  name='phone_number'
                  rules={[
                    {
                      required: true,
                      message: 'Please input Phone Number',
                    },
                  ]}
                >
                  <TextInput type='phone' />
                </FormItem>

                {notification === 'loading' ? (
                  <Spinner />
                ) : (
                  <LoggerButton full htmlType='submit' text='Add Bank' />
                )}
              </Form>
            </HeroContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            onClick={() => dispatcher(toggleModal(''))}
            color='primary'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={notification === 'error'}
        type='error'
        message={error}
        onClose={() => {
          //dispatcher(toggleModal(''));
          dispatcher(clearNotification());
        }}
      />
      <SnackBar
        open={notification === 'success'}
        type='success'
        message={message}
        onClose={() => {
          dispatcher(clearNotification());
        }}
      />
    </>

    /*  <Modal open={true} onCancel={() => dispatcher(toggleModal(''))}>
      <LTitle title='Account Details' />
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}  >
        <FormItem
          label='Amount'
          name='amount'
          rules={[
            {
              required: true,
              message: 'Please input an amount',
            },
          ]}
        >
          <TextInput type='number' />
        </FormItem>
        <FormItem
          label='Phone Number'
          name='phone_number'
          rules={[
            {
              required: true,
              message: 'Please input Phone Number',
            },
          ]}
        >
          <TextInput type='phone' />
        </FormItem>

        {notification === 'loading' ? (
          <Spinner />
        ) : (
          <LoggerButton full htmlType='submit' text='Add Bank' />
        )}
      </Form>
      <Button
          size='small'
          onClick={() => dispatcher(toggleModal(''))}
          color='primary'
        >
          Cancel
        </Button>
    </Modal> */
  );
};
