import React, { useState } from "react";
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

export const ParticipantProtectedRoute = ({
  component: Component,
  ...reset
}) => {
  const { url, path } = useRouteMatch();

  return (
    <>
      <Route
        {...reset}
        render={(props) => {
          return localStorage.token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname:
                  // props.location.state === undefined
                  // ?
                  "/",
                // : props.location.state.from.pathname,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }}
      />
    </>
  );
};
