import React from "react";
import styled from "styled-components";
import {
  darkgreen,
  lightgreen,
  activeShadow,
  inActiveShadow,
  black,
  white,
  grey1,
  grey2,
  activeShadow2,
} from "../globalAssets/theme";
import {MessageNotificationSuccess} from "../globalComponent/Index";
import { useHistory } from "react-router-dom";
import { UseActiveSurveyId } from "../components/TakeSurvey/context";
import { fontFamily } from "../globalAssets/fontFamily";
import { Button as AntButton } from "antd";
import { device } from "../globalAssets/mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { UseActiveParticipantId } from "../components/TakeSurvey/context";

export const SurveyCard = (props) => {
  const history = useHistory();
  //const userInfo = useSelector((state) => state.userInfo.userInfo);
  const {
    userInfo,
    setActiveParticipantId,
  } = UseActiveParticipantId();

  const takeSurvey = (id) => {

    if (userInfo?.progress !== 100) {
      console.log("You can't take survey now");
      MessageNotificationSuccess("Incomplete Profile! Please Complete your profile to take survey ");
    } else {
      history.push(`/dashboard/take-survey/${id}`);
      setActiveParticipantId(userInfo?.id);
    }


    
  };
  return (
    <>
      <CardComponentContainer>
        <Title>{props.questionNumber} Questions</Title>
        <Hr />
        <QuestionTitle>{props.questionTitle}</QuestionTitle>
        <StyledButton
          id={props.id}
          onClick={() => {
            takeSurvey(props.id);
          }}
        >
          Take Survey
        </StyledButton>
      </CardComponentContainer>{" "}
    </>
  );
};

const CardComponentContainer = styled.div`
  ${"" /* height: 40px; */}
  width: 80%;
  border: none;
  border-radius: 5px;
  outline: none;
  text-align: center;
  background: ${white};
  ${"" /* box-shadow: 0px 0px 10px ${activeShadow2}; */}
  box-shadow:0px 3px 8px -1px rgba(50, 50, 71, 0.08);
  margin: 30px auto 20px auto;
`;
const Title = styled.h5`
  font-family: ${fontFamily.Sora};
  font-size: 10px;
  text-align: start;
  padding: 10px 0 0 15px;
`;
const Hr = styled.hr`
  background-color: ${inActiveShadow};
  height: 1px;
  ${"" /* border: 1px solid ${inActiveShadow}; */}
  border:none;
`;

const QuestionTitle = styled.h3`
  font-family: ${fontFamily.Sora};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-color: ${black};
  margin: 10px 0;
`;

const StyledButton = styled(AntButton)`
  &.ant-btn,
  .ant-btn:hover,
  .ant-btn:focus {
    width: 90%;
    font-family: ${fontFamily.Sora};
    border: none;
    outline: none;
    margin: 0 auto;
    font-size: 11px;
    border-radius: 5px;
    text-align: center;
    margin: 10px 0;
    background: linear-gradient(to right, ${darkgreen} 0%, ${lightgreen} 50%);
    color: ${white};
    @media ${device.laptop} {
      width: 80%;
    }
  }
`;
