export const inActiveShadow = "#00000019";
export const activeShadow = "#00000009";
export const activeShadow2 = "#00000019";
export const activeShadow3 = "#00000029";
export const grey1 = "#00000069";
export const grey2 = "#00000089";
export const darkgreen = "#074F19";
export const lightgreen = "#259B01";
export const lightgreen1 = "#259B01";
export const lightgreen2 = "#259B01";
export const darkorange = "#E96F06";
export const lightorange = "#F1AE02";
export const white = "white";
export const black = "black";
export const background = "#f7fafc";
