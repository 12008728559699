import React from 'react';
import styled from "styled-components";
import { grey1, lightgreen } from "../globalAssets/theme";
import { fontFamily } from "../globalAssets/fontFamily";

export const Text1 = styled.h5`
  color: ${grey1};
  margin: 10px 0;
  font-size: 11px;
  test-align: center;
  font-family: ${fontFamily.inter};
  font-weight: 600;
`;
export const Text2 = styled.span`
  font-size: 11px;
  test-align: center;
  color: ${lightgreen};
  cursor: pointer;
`;
