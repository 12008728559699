import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import {
  registerParticipant,
  clearRegister,
} from "../../redux-toolkit/registerSlice";
import { useHistory } from "react-router-dom";
import { setVisible } from "../../redux-toolkit/resendVerificationSlice";

import { UseLoginMail } from "../Login/context";
import {
  HeroContainer,
  IPerson,
  IMail,
  Checkbox,
  LTitle,
  Form,
  Spinner,
  Notification,
  FormItem,
  TextInput,
  TextInputPassword,
  LoggerButton,
  Text1,
  Text2,
  DropDown,
  LongNotification,
  ILock,
  IPhone,
  MessageNotificationErrorLong,
  MessageNotificationSuccessLong,
} from "../../globalComponent/Index";
import { useStateLga } from "../../helperFunctions/useStatesLgas";

const Register = () => {
  const { email, setEmail } = UseLoginMail();
  const history = useHistory();
  const dispatcher = useDispatch();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    state: "",
    lga: "",
    agree: "",
  });
  const notification = useSelector(
    (state) => state.register.registerNotification
  );
  const error = useSelector((state) => state.register.error);

  const onFinish = (values) => {
    // console.log("success:", values);
    dispatcher(registerParticipant(values));
    setEmail(values.email);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  useEffect(() => {
    if (notification === "success") {
      dispatcher(setVisible());
      dispatcher(clearRegister());
        // LongNotification(
        //   "success",
        //   "User Successfully created",
        //   "Please verify your account before login"
        // );
      MessageNotificationSuccessLong(
        "Please proceed to email to verify your account before login"
      );
      history.push("/");
    }
    if (notification === "error") {
      // LongNotification(
      //   "error",
      //   "Error creating user",
      //   "Please check credentials and try again",
      // );
      MessageNotificationErrorLong("Error: Please check credentials and try again");
      dispatcher(clearRegister());
    }
  }, [notification]);

  const [states, lgas, setStateLgas] = useStateLga();
  return (
    <HeroContainer style={{ paddingTop: "5px" }}>
      <Row justify="center">
        <Col
          span={6}
          xs={24}
          sm={12}
          md={11}
          lg={9}
          xl={6}
          // offset={6}
          justifycontent="center"
        >
          {" "}
          <LTitle title="Create an Account" />
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <FormItem
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input First name",
                },
              ]}
            >
              <TextInput
                type="text"
                onChange={(e) =>
                  setData({ ...data, firstName: e.target.value })
                }
                icon={<IPerson />}
              />
            </FormItem>
            <FormItem
              type="text"
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please input Last Name",
                },
              ]}
            >
              <TextInput
                onChange={(e) => setData({ ...data, lastName: e.target.value })}
                icon={<IPerson />}
              />
            </FormItem>
            <FormItem
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your mail",
                },
                {
                  type: "email",
                  message: "The format is not an email format",
                },
              ]}
            >
              <TextInput
                onChange={(e) => setData({ ...data, email: e.target.value })}
                icon={<IMail />}
              />
            </FormItem>
            <FormItem
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password",
                },
              ]}
            >
              <TextInputPassword
                onChange={(e) => setData({ ...data, password: e.target.value })}
                icon={<ILock />}
              />
            </FormItem>
            <FormItem
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
            >
              <TextInput
                type="number"
                max={11}
                onChange={(e) =>
                  setData({ ...data, phoneNumber: Number(e.target.value) })
                }
                icon={<IPhone />}
              />
            </FormItem>
            <FormItem
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please select a state",
                },
              ]}
            >
              <DropDown
                values={states}
                onChange={(event) => setStateLgas(event.currentTarget.value)}
              />
            </FormItem>
            <FormItem
              label="L.G.A"
              name="lga"
              rules={[
                {
                  required: true,
                  message: "Please select a L.G.A",
                },
              ]}
            >
              <DropDown values={lgas} />
            </FormItem>
            <FormItem
              initialValue={false}
              name="agree"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined,
                  type: "boolean",
                  message: "Please agree to the terms and conditions.",
                },
              ]}
            >
              <div
                style={{
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "center",
                }}
              >
                <Text2>
                  <Checkbox
                    onChange={(e) =>
                      setData({ ...data, agree: e.target.checked })
                    }

                    // value={data.agree}
                  />
                </Text2>
                <Text1 style={{ marginTop: "0px" }}>
                  By registering I agree to SurveyPlus Software's Terms of
                  Service and Privacy Policy
                </Text1>
              </div>
            </FormItem>
            {notification === "loading" ? (
              <Spinner />
            ) : (
              <LoggerButton htmlType="submit" text="GET STARTED" />
            )}
            <Text1 onClick={() => history.push("/")}>
              Already have an account? <Text2>Login Here</Text2>
            </Text1>
          </Form>{" "}
        </Col>
      </Row>
    </HeroContainer>
  );
};
export default Register;
