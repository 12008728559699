import { Gradient } from "@material-ui/icons";
import styled from "styled-components";
import { Statistic } from "antd";
import { fontFamily } from "../../globalAssets/fontFamily";
import { device } from "../../globalAssets/mediaQueries";
import {
  black,
  white,
  lightgreen,
  lightgreen2,
  activeShadow,
  inActiveShadow,
  darkgreen,
} from "../../globalAssets/theme";

export const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;
export const SubHeader = styled.div`
  ${"" /* position: relative; */}
  text-align: center;
  line-height: 10px;
  background-color: ${black};
  height: 70px;
  width: 100%;
  padding-top: 10px;
  @media ${device.laptop} {
    display: none;
  }
`;

export const SubHeadText1 = styled.h2`
  font-size: 26px;
  margin-top: 5px;
  color: ${white};
  font-family: ${fontFamily.DancingSpirit};
`;
export const SubHeadText2 = styled.h4`
  font-size: 12px;
  color: ${white};
  font-family: ${fontFamily.inter};
`;
export const ImageContainer = styled.div`
  width: fit-content;
  z-index: 200;
  top: 85px;
  left: 40vw;
  margin: 0 auto;
  border: 3px solid ${white};
  border-radius: 50%;
`;
export const Subheader2 = styled.div`
  font-family: ${fontFamily.inter};
  width: 95%;
  height: 120px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 5px 5px 15px;
  position: relative;
  box-shadow: 3px 3px 3px ${activeShadow};
  @media ${device.laptop} {
    display: none;
  }
`;

export const SubHeader3 = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  background: transparent;
  display: flex;
  aling-items: center;
  justify-content: center;
  flew-direction: row;
  @media ${device.laptop} {
    display: none;
  }
`;

export const WelcomeTitle = styled.h3`
  font-family: ${fontFamily.inter};
  position: absolute;
  top: 60px;
  ${"" /* margin: 10px 0 */}
  ${"" /* right: 50%; */}
  font-size: 15px;
  color: ${black};
`;

export const WelcomeName = styled.span`
  font-weight: bold;
`;
export const RedeemCard = styled.div`
  ${"" /* position: absolute; */}
  top: 65px;
  ${"" /* left: 3%; */}
  ${"" /* bottom: 0; */}
  text-align: right;
  margin-top: 10px;
  padding: 5px 10px 0 10px;
  width: 95%;

  color: ${white};
  border: none;
  outline: none;
  border-radius: 5px;
  background-image: ${({ color1, color2 }) =>
    `linear-gradient(to right, ${color1}, ${color2}) `};
`;
export const RedeemCardTitle = styled.h5`
  color: ${white};
  font-weight: 300;
  ${"" /* line-height:1px; */}
  font-size: 9px;
  font-family: ${fontFamily.Sora};
`;
export const RedeemCardValue = styled.h5`
  color: ${white};
`;
export const RedeemcardStatVal = styled(Statistic)`
  font-size: 20px !important;
  & .ant-statistic-content {
    font-size: 16px;
    margin-top: -10px;
  }
  & .ant-statistic-content-value-int {
    text-align: right !important;
    color: ${white} !important;
    line-height: 1px;
  }
  & .ant-statistic-content-prefix {
    color: ${white} !important;
  }
`;
