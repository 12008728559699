import React from 'react';
import { FaPhoneAlt } from "react-icons/fa";

import styled from "styled-components";
import { black, grey1 } from "../globalAssets/theme";
export const IPhone = () => {
  return <StyledProfile />;
};

const StyledProfile = styled(FaPhoneAlt)`
  font-size: 14px;
  ${"" /* padding: 2px; */}
  color: ${grey1};
`;
