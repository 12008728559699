import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { Row, Col } from "antd";
import { SideNav } from "./SideNav";
import {
  Drawer,
  ISurvey,
  IHome,
  INote,
  Image,
  ISurveyDrawer,
  IDashboard,
  IWallet,
  IProfile,
  ILogout,
  Notification,
} from "../../globalComponent/Index";
import { closeDrawer } from "../../redux-toolkit/drawerSlice/drawerSlice";
import {
  IconContainer,
  FooterContainer,
  Footername,
  FooterHolder,
  DrawerContainer,
  DrawerHeader,
  TitleName,
  TitleAccount,
  DrawerLink,
  DrawerLinkContainer,
} from "./Styles";
import { fetchUserInfo } from "../../redux-toolkit/fetchUserInfoSlice";
import { getTransctonHistory } from "../../redux-toolkit/transactionSlice";
import { DashboardRoute } from "../../App";
import { HandleLogOut } from "../../helperFunctions/logOut";

const Dashboard = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher(fetchUserInfo());
    dispatcher(getTransctonHistory());
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  const drawerValue = [
    { text: "Dashboard", link: `${path}`, icon: <IDashboard /> },
    { text: "My Wallet", link: `${path}/wallet`, icon: <IWallet /> },
    { text: "Surveys", link: `${path}/survey`, icon: <ISurveyDrawer /> },
    { text: "My Profile", link: `${path}/profile`, icon: <IProfile /> },
    { text: "Logout", icon: <ILogout /> },
  ];
  const drawerLink = drawerValue.map((drawer, id) => (
    <DrawerLinkContainer
      style={{ marginTop: "10px", background: "white" }}
      key={id}
      onClick={() => {
        dispatcher(closeDrawer());
        drawer.link ? history.push(`${drawer.link}`) : handleLogOut();
      }}
    >
      {drawer.icon}
      <DrawerLink>{drawer.text}</DrawerLink>
    </DrawerLinkContainer>
  ));
  const nightMode = false;
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const [pos, setPos] = useState({
    lat: null,
    log: null,
  });

  //setting user's location
  useEffect(() => {
    console.log({
      userInfo:
        !userInfo.lat ||
        !userInfo.log ||
        (userInfo.lat && userInfo.log && userInfo.log === userInfo.lat),
    });
    if (
      !userInfo.lat ||
      !userInfo.log ||
      (userInfo.lat && userInfo.log && userInfo.log === userInfo.lat)
    ) {
      //grabbing the user's location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) =>  {
          console.log({ position });
          if (position.coords.latitude || position.coords.longitude) {
            setPos((prev) => ({
              ...prev,
              lat: position.coords.latitude.toString(),
              log: position.coords.longitude.toString(),
            }));
          }
        }, (error) => {
          console.log({ error })
          Notification('error', 'Location Denied', 'Please enable location perssion');
        });
      }
    }
  }, [userInfo]);

  //post request to update user's lat && log on the database
  useEffect(() => {
    const data = new FormData();
    const token = localStorage.token;

    if (pos.lat !== null && pos.log !== null) {
      data.append("lat", pos.lat);
      data.append("log", pos.log);

      const config = {
        method: "post",
        url: "https://api.getsurveyplus.com/api/update-location",
        headers: {
          Authorization: `Token ${token}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [pos.lat, pos.log]);

  return (
    <DrawerLinkContainer>
      <SideNav SideNavLinks={drawerValue} />
      <Drawer onClose={() => dispatcher(closeDrawer())}>
        <DrawerContainer>
          <DrawerHeader>
            <Image
              // src={`https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`}
              src={
                userInfo?.profile_pic &&
                `data:image/png;base64,${userInfo?.profile_pic}`
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <TitleName>
                {userInfo?.firstname} {userInfo?.lastname}
              </TitleName>
              <TitleAccount>Participant Account</TitleAccount>
            </div>
          </DrawerHeader>
          <div
            style={{
              margin: "20px 12px",
            }}
          >
            {drawerLink}
            <DrawerLinkContainer
              style={{
                fontSize: "10px",
                alignItems: "center",
                marginTop: "10px",
                background: "white",
              }}
            >
              {/* {nightMode ? "🌙" : "☀️"} */}
              {/* <DrawerLink>{nightMode ? "Night Mode" : "Light Mode"}</DrawerLink> */}
            </DrawerLinkContainer>
          </div>
        </DrawerContainer>
      </Drawer>{" "}
      <DashboardRoute />
      <FooterContainer>
        <Row>
          <Col xs={{ span: 6, zoffset: 1 }}>
            <FooterHolder onClick={() => history.push(`${path}`)}>
              <IconContainer>
                <IHome />
              </IconContainer>
              <Footername>Home</Footername>
            </FooterHolder>
          </Col>
          <Col xs={{ span: 6, offset: 1 }}>
            <FooterHolder onClick={() => history.push(`${path}/survey`)}>
              <IconContainer>
                <ISurvey />
              </IconContainer>
              <Footername>Surveys</Footername>
            </FooterHolder>
          </Col>

          <Col xs={{ span: 6, offset: 2 }}>
            <FooterHolder onClick={() => history.push(`${path}/surveyhistory`)}>
              <IconContainer>
                <INote />
              </IconContainer>
              <Footername>Surveys History</Footername>
            </FooterHolder>
          </Col>
        </Row>
      </FooterContainer>
    </DrawerLinkContainer>
  );
};
export default Dashboard;
