import React from "react";
import { fontFamily } from "../globalAssets/fontFamily";
import { Form as AntForm } from "antd";
import styled from "styled-components";

export const Form = ({ children, submit, onFinish, onFinishFailed }) => {
  return (
    <StyledForm
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {children}
    </StyledForm>
  );
};

export const FormItem = ({
  label,
  type,
  valuePropName,
  rules,
  children,
  name,
  initialValue,
}) => {
  return (
    <StyleFormItem
      style={{
        fontSize: "5px",
        marginBottom: "10px",
      }}
      label={
        <label
          style={{ fontSize: "12px", marginBottom: "-15px", fontWeight: "600" }}
        >
          {label}
        </label>
      }
      type={type}
      // rules={rules}
    >
      <AntForm.Item
        rules={rules}
        name={name}
        valuePropName={valuePropName}
        initialValue={initialValue}
        noStyle
      >
        {children}
      </AntForm.Item>
    </StyleFormItem>
  );
};

export const FormItemDisplay = ({ children, label, type, rules, name }) => {
  return (
    <StyleFormItem
      style={{
        fontSize: "5px",
        marginBottom: "10px",
      }}
      label={
        <label
          style={{
            fontSize: "12px",
            marginTop: "3px",
            marginBottom: "-5px",
            fontWeight: "600",
            marginLeft: "15px",
          }}
        >
          {label}
        </label>
      }
      name={name}
      type={type}
      rules={rules}
    >
      {children}{" "}
    </StyleFormItem>
  );
};

const StyledForm = styled(AntForm)`
  width: 100%;
`;
const StyleFormItem = styled(AntForm.Item)`
  font-size: 2px;
  color: red;
  font-family: ${fontFamily.inter};
  .ant-form-item {
    margin-bottom: none;
    color: red;
  }
`;
