import React, { useEffect } from "react";
import {
  MobileHeader,
  Image,
  SurveyCard,
  CompoenentContainer,
  IMenu,
  LaptopHeader,
  EmptyData,
} from "../../globalComponent/Index";
import { showDrawer } from "../../redux-toolkit/drawerSlice/drawerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { AccountCard } from "./AccountCard";
import {
  lightgreen,
  lightgreen2,
  darkgreen,
  lightorange,
  darkorange,
} from "../../globalAssets/theme";
import { fetchSurveyList } from "../../redux-toolkit/fetchSurveysListSlice";
import {
  HeroContainer,
  SubHeader,
  Subheader2,
  WelcomeTitle,
  RedeemCard,
  RedeemCardValue,
  RedeemCardTitle,
  RedeemcardStatVal,
  WelcomeName,
  SubHeadText1,
  SubHeadText2,
  SubHeader3,
  ImageContainer,
} from "./styles";
import { Info } from "@material-ui/icons";

const YourDashboard = () => {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const surveyList = useSelector((state) => state.surveyList.surveyList);
  const notification = useSelector((state) => state.login.loginNotification);

  // console.log("login notification from dashboard", notification);
  // console.log("user info from dashboard", userInfo);

  const dispatcher = useDispatch();
  // console.log("the survey list is ", surveyList);
  useEffect(() => {
    dispatcher(fetchSurveyList(1));
  }, []);


  console.log({surveyList})
  const surveyCardArray = surveyList
    ? surveyList.map((survey, id) => (
        <Col xs={24} sm={12} md={8} key={id}>
          <SurveyCard
            questionNumber={survey.num_questions}
            questionTitle={survey.survey_name}
            id={survey.id}
          />
        </Col>
      ))
    : "Loading... please wait";

    
  return (
    <CompoenentContainer>
      <IMenu onClick={() => dispatcher(showDrawer())} />
      <MobileHeader title="Your Dashboard" />
      <LaptopHeader title="Your Dashboard" />
      <AccountCard />
      <SubHeader>
        <SubHeadText1>Take Survey</SubHeadText1>
        <SubHeadText2>Earn Money</SubHeadText2>
      </SubHeader>
      <SubHeader3>
        {userInfo?.profile_pic && (
          <ImageContainer>
            <Image src={`data:image/png;base64,${userInfo?.profile_pic}`} />
          </ImageContainer>
        )}
        <WelcomeTitle>
          Welcome{" "}
          <WelcomeName>
            {userInfo?.firstname ? userInfo?.firstname : "loading..."}
          </WelcomeName>
        </WelcomeTitle>
      </SubHeader3>
      <Subheader2>
        <Row justify="center">
          <Col xs={12} sm={12} md={12}>
            <RedeemCard color1={darkgreen} color2={lightgreen2}>
              <RedeemCardTitle>Wallet</RedeemCardTitle>
              <RedeemCardValue>
                <RedeemcardStatVal prefix="₦" value={userInfo?.wallet} />
              </RedeemCardValue>
            </RedeemCard>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <RedeemCard color1={darkorange} color2={lightorange}>
              <RedeemCardTitle>LifeTime Points</RedeemCardTitle>
              <RedeemCardValue>
                <RedeemcardStatVal value={userInfo?.points} />
              </RedeemCardValue>
            </RedeemCard>
          </Col>
        </Row>
      </Subheader2>
      <Row>{surveyCardArray}</Row>
    </CompoenentContainer>
  );
};
export default YourDashboard;
