import styled from "styled-components";
import { fontFamily } from "../../globalAssets/fontFamily";
import { device } from "../../globalAssets/mediaQueries";
import {
  white,
  lightgreen1,
  lightgreen2,
  lightgreen,
} from "../../globalAssets/theme";

export const Header = styled.div`
  height: 30px;
  width: 100%;
  font: 15px;
  padding: 5px 0 0 0;
  padding-left: 10px;
  color: ${white};
  font-family: ${fontFamily.Sora};
  background: ${lightgreen};
  @media ${device.laptop} {
    display: none;
  }
`;
