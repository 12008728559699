import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SideEffect } from '../api/sideEffects';

export const buyAirtime = createAsyncThunk(
  'transactionsSlice/buyAirtime',
  async (data, { rejectWithValue }) => {
    console.log({ data });
    try {
      const response = await SideEffect.buyAirtime(data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      if (error.response) {
        console.log({ error: error.response.data.error });
        return rejectWithValue(error.response.data.error);
      }
    }
  },
);

export const transcferToBank = createAsyncThunk(
  'transactionsSlice/transferToBank',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SideEffect.fundBankAccount(data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const getTransctonHistory = createAsyncThunk(
  'transactionsSlice/transactionHistory',
  async (data, payload) => {
    try {
      const response = await SideEffect.getTransactionHistory();
      console.log('TRANSACTIONS', response);
      return response.data?.data?.transactions;
    } catch (error) {
      console.log({ error });
      return payload.rejectWithValue([], error.error);
    }
  },
);

const initialState = {
  visible: '',
  notification: '',
  error: '',
  message: '',
  allTransactions: [],
};

const transctionSlice = createSlice({
  name: 'transactionsSlice',
  initialState,
  reducers: {
    toggleModal: (state, { payload }) => {
      state.visible = payload;
    },

    clearNotification: (state) => {
      state.notification = '';
    },
  },

  extraReducers: {
    [buyAirtime.fulfilled]: (state, { payload }) => {
      console.log({ payload });
      state.notification = 'success';
      state.message = payload.data.message;
    },
    [buyAirtime.pending]: (state, { payload }) => {
      state.notification = 'loading';
    },
    [buyAirtime.rejected]: (state, { payload }) => {
      state.notification = 'error';
      state.error = payload;
    },
    [transcferToBank.fulfilled]: (state, { payload }) => {
      state.notification = 'success';
      state.message = payload.data.message;
    },
    [transcferToBank.pending]: (state, { payload }) => {
      state.notification = 'loading';
    },
    [transcferToBank.rejected]: (state, { payload }) => {
      state.notification = 'error';
      state.error = payload;
    },
    [getTransctonHistory.fulfilled]: (state, { payload }) => {
      state.allTransactions = payload.reverse();
    },
  },
});

export const { toggleModal, clearNotification } = transctionSlice.actions;

export const selectTransactions = (state) => state.transactions;

export default transctionSlice.reducer;
