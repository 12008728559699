import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { SideEffect } from "../api/sideEffects";
const initialState = {
  data: "",
  error: "",
  updateNotification: "",
};

export const requestForgotPassword = createAsyncThunk(
  "forgotPassword/request",
  async (payload, { rejectWithValue }) => {
    try {
      const forgotPassword = await SideEffect.forgotPassword(payload);
      //   console.log("the payload", payload);
      return forgotPassword;
    } catch (err) {
      // console.log("the error", err);
      // console.log("the payload", payload);

      return rejectWithValue([], err);
    }
  }
);
const forgotPassword = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    clearNotification: (state) => {
      state.updateNotification = "";
    },
  },
  extraReducers: {
    [requestForgotPassword.fulfilled]: (state, { payload }) => {
      state.updateNotification = "success";
      state.data = payload.data.message;
      // console.log("request password payload", payload.data.message);
    },
    [requestForgotPassword.pending]: (state, { payload }) => {
      state.updateNotification = "loading";
    },
    [requestForgotPassword.rejected]: (state, { error, payload }) => {
      state.updateNotification = "error";
      // console.log("Error message", error);
    },
  },
});

export const { clearNotification } = forgotPassword.actions;

export default forgotPassword.reducer;
