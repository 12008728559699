import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

const initialState = {
  fetchSurveyNotification: "",
  error: "",
  surveyList: [],
};

export const fetchSurveyList = createAsyncThunk(
  "fetchSurveyListSlice/surveyList",
  async (payload, { rejectWithValue }) => {
    try {
      const surveyList = await SideEffect.fetchSurvey(payload);
      console.log({ 'TESTING': surveyList })
      // console.log("survey from slice", surveyList.data.data.surveys);
      return surveyList.data.data.surveys;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const fetchSurveyListSlice = createSlice({
  name: "fetchSurveyListSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSurveyList.fulfilled]: (state, action) => {
      state.fetchSurveyNotification = "success";
      console.log({action })
      state.surveyList = action.payload;
    },
    [fetchSurveyList.pending]: (state, { payload }) => {
      state.fetchSurveyNotification = "loading";
      //state.surveyList = [];
    },
    [fetchSurveyList.rejected]: (state, { error }) => {
      state.fetchSurveyNotification = "error";
      state.error = error;
    },
  },
});

export default fetchSurveyListSlice.reducer;
