import React from 'react';
import styled from "styled-components";
import { grey1, lightgreen } from "../globalAssets/theme";
import { fontFamily } from "../globalAssets/fontFamily";
import { device } from "../globalAssets/mediaQueries";

export const HeroContainer = styled.div`
  ${"" /* width: fit-content; */}
  padding: 50px 10px 10px 10px;
  text-align: center;
  margin: 0 auto;
  @media ${device.laptop} {
    background: none;
    ${"" /* text-align: left; */}
    padding: 50px 20px 10px 40px;
  }
`;
