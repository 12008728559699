import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

const initialState = {
  error: "",
  screening_question: [],
  questionnaire: [],
  loading: "",
};

export const fetchParticipantSingleSurveyAction = createAsyncThunk(
  "participantSingleSurvey/singleSurvey",
  async (payload, { rejectWithValue }) => {
    try {
      const singleSurvey = await SideEffect.fetchSingleSurvey(payload);
      // console.log("survey list from slice", singleSurvey);
      return singleSurvey.data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const participantSingleSurvey = createSlice({
  name: "participantSingleSurvey",
  initialState,
  reducers: {
    clearSurveyList: (state) => {
      state.screening_question = [];
      state.questionnaire = [];
    },
  },
  extraReducers: {
    [fetchParticipantSingleSurveyAction.fulfilled]: (state, action) => {
      state.loading = "done";
      let newQuestionnaire = action.payload.questionnaire.map((item) => {
        let options = item.options.map((opt) => opt.replace(/<!&&!>/g, ","));
        let category = item.category.map((opt) => opt.replace(/<!&&!>/g, ","));
        item.options = options;
        item.category = category;
        return item;
      });

      let newScreening_question = action.payload.screening_question.map(
        (item) => {
          let options = item.options.map((opt) => opt.replace(/<!&&!>/g, ","));
          let category = item.category.map((opt) =>
            opt.replace(/<!&&!>/g, ",")
          );
          item.options = options;
          item.category = category;
          return item;
        }
      );

      state.screening_question = newScreening_question;
      state.questionnaire = newQuestionnaire;
    },
    [fetchParticipantSingleSurveyAction.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [fetchParticipantSingleSurveyAction.rejected]: (state, { error }) => {
      state.loading = "error";
      state.error = error;
    },
  },
});
export const { clearSurveyList } = participantSingleSurvey.actions;
export default participantSingleSurvey.reducer;

// export const submitMainResponseAction = createAsyncThunk(
//   "submitMainResponse/questionaire",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const singleSurvey = await SideEffect.submitMainResponse(payload);
//       console.log("survey from slice", singleSurvey);
//       //   return singleSurvey
//     } catch (err) {
//       return rejectWithValue([], err);
//     }
//   }
// );

// const submitMainResponse = createSlice({
//   name: "submitMainResponse",
//   initialState: {
//     mainResponseNotify: "",
//   },
//   reducers: {
//     mainResponseNotifyAction: (state, { payload }) => {
//       state.mainResponseNotify = payload;
//     },
//   },
//   extraReducers: {
//     [fetchParticipantSingleSurveyAction.fulfilled]: (state, action) => {
//       state.mainResponseNotify = "success";
//     },
//     [fetchParticipantSingleSurveyAction.pending]: (state, { payload }) => {
//       state.mainResponseNotify = "loading";
//     },
//     [fetchParticipantSingleSurveyAction.rejected]: (state, { error }) => {
//       state.mainResponseNotify = "error";
//     },
//   },
// });

// export const { mainResponseNotifyAction } = submitMainResponse.actions;
// export const { mainResponseNotify } = submitMainResponse.reducer;

// export const submitScreeningResponseAction = createAsyncThunk(
//   "submitScreeningResponse/screening",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const singleSurvey = await SideEffect.submitScreeningResponse(payload);
//       console.log("survey from slice", singleSurvey);
//       //   return singleSurvey
//     } catch (err) {
//       return rejectWithValue([], err);
//     }
//   }
// );

// const submitScreeningResponse = createSlice({
//   name: "submitScreeningResponse",
//   initialState: {
//     screeningResponseNotify: {
//       status: "",
//       result: "",
//     },
//   },
//   reducers: {
//     screeningResponseNotifyAction: (state, { payload }) => {
//       state.screeningResponseNotify = payload;
//     },
//   },
//   extraReducers: {
//     [fetchParticipantSingleSurveyAction.fulfilled]: (state, action) => {
//       state.screeningResponseNotify.status = "success";
//       state.screeningResponseNotify.result = action.payload;
//     },
//     [fetchParticipantSingleSurveyAction.pending]: (state, { payload }) => {
//       state.screeningResponseNotify.status = "loading";
//     },
//     [fetchParticipantSingleSurveyAction.rejected]: (state, { error }) => {
//       state.screeningResponseNotify.status = "error";
//     },
//   },
// });

// export const {
//   screeningResponseNotifyAction,
// } = submitScreeningResponse.actions;
// export const { screeningResponseNotify } = submitScreeningResponse.reducer;
