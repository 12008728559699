import React from 'react';
import { VscHistory } from "react-icons/vsc";

import styled from "styled-components";

export const HistoryIcon = () => {
  return <StyledHistory />;
};

const StyledHistory = styled(VscHistory)`
  font-size: 1.1vw;
  margin: 0 10px 0 0;
`;
