import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

const initialState = {
  loginNotification: "",
  error: "",
  loginContent: "",
  token: "",
  verified: false,
};

export const loginParticipant = createAsyncThunk(
  "login/participant",
  async (payload, { rejectWithValue }) => {
    try {
      const loginData = await SideEffect.loginUser(payload);
      return loginData.data;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearLogin: (state) => {
      state.loginNotification = "";
    },
  },
  extraReducers: {
    [loginParticipant.fulfilled]: (state, action) => {
      state.loginNotification = "success";
      state.loginContent = action.payload;
      if (action.payload.verified === 0) {
        state.token = action.payload.token;
        state.verified = true;
        //state.token = "";
        //state.verified = false;
      } else if (action.payload.verified === 1) {
        state.verified = true;
        state.token = action.payload.token;
      }
    },
    [loginParticipant.pending]: (state, { payload }) => {
      state.loginNotification = "loading";
    },
    [loginParticipant.rejected]: (state, { error, payload }) => {
      state.loginNotification = "error";
      state.error = error;
    },
  },
});

export const loginInfo = createSelector(
  (state) => ({
    token: state.token,
    loading: state.loading,
    error: state.error,
  }),
  (state) => state
);
export default loginSlice.reducer;
export const { clearLogin } = loginSlice.actions;
