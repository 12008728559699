import React from 'react';
import { MdLandscape } from "react-icons/md";
import styled from "styled-components";
import { white, black } from "../globalAssets/theme";
export const ISurvey = () => {
  return <StyledLanscape />;
};
export const ISurveyDrawer = () => {
  return <StyledLanscapeDrawer />;
};

const StyledLanscape = styled(MdLandscape)`
  font-size: 20px;
  padding: 2px;
  color: ${white};
`;

const StyledLanscapeDrawer = styled(MdLandscape)`
  font-size: 20px;
  ${"" /* padding: 2px; */}
  color: ${black};
`;

export const LandScapeIcon = () => {
  return <StyleLandScapeIcon />;
};
const StyleLandScapeIcon = styled(MdLandscape)`
  font-size: 1.1vw;
  margin: 0 10px 0 0;
`;
