import React from "react";
import styled from "styled-components";
import { Table as AntTable } from "antd";
import { device } from "../globalAssets/mediaQueries";

export const Table = (props) => {
  return (
    <StyledTable
      //   size="small"
      {...props}
      dataSource={props.dataSource}
      columns={props.columns}
    />
  );
};
const StyledTable = styled(AntTable)`
  display: none;
  @media ${device.laptop} {
    display: block;
    padding: 0 20px;
    width: 98% ${"" /* box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05); */};
    .ant-table table {
      font-size: 13px;
    }
  }
`;
