import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import { SideEffect } from "../api/sideEffects";

const initialState = {
  visible: false,
  notification: "",
  error: "",
  message: "",
};

export const addAccount = createAsyncThunk(
  "addAccount/update",
  async (payload, { rejectWithValue }) => {
    try {
      await SideEffect.updateAccountDetails(payload);
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const AddAccountSlice = createSlice({
  name: "addAccount",
  initialState,
  reducers: {
    showModal: (state) => {
      state.visible = true;
    },
    closeModal: (state) => {
      state.visible = false;
    },
    clearNotification: (state) => {
      state.notification = "";
    },
  },
  extraReducers: {
    [addAccount.fulfilled]: (state, { payload }) => {
      state.notification = "success";
      state.userInfo = payload;
      state.message = "Account details successfully added";
    },
    [addAccount.pending]: (state, { payload }) => {
      state.notification = "loading";
      // state.fetchuserInfoNotification = "";
    },
    [addAccount.rejected]: (state, { error }) => {
      state.notification = "error";
      state.error = error;
    },
  },
});

export const {
  showModal,
  closeModal,
  clearNotification,
} = AddAccountSlice.actions;

export default AddAccountSlice.reducer;
