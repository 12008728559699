import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Tag, Badge } from "antd";
import { fontFamily } from "../globalAssets/fontFamily";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../redux-toolkit/fetchUserInfoSlice";
import { device } from "../globalAssets/mediaQueries";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ILogout, IProfile } from "./Index";
import {
  black,
  darkgreen,
  grey1,
  lightgreen1,
  lightgreen2,
  white,
} from "../globalAssets/theme";
import { UserProfileImage } from "./Image";
import { FiLogOut } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";

export const MobileHeader = ({ changeColor, title }) => {
  return <MHeader changeColor={changeColor}>{title}</MHeader>;
};

export const LaptopHeader = ({ title }) => {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const history = useHistory();

  const handleLogOut = () => {
    history.push("/");
    localStorage.removeItem("token");
  };

  const menu = (
    <Menu style={{ width: "100px" }}>
      <Menu.Item
        style={{
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          color: `${grey1}`,
          // justifyContent: "space-around",
        }}
        onClick={() => history.push(`/dashboard/profile`)}
        icon={<CgProfile />}
      >
        <span style={{ marginLeft: "5px" }}>Profile</span>
      </Menu.Item>
      <Menu.Item
        style={{
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-around",
        }}
        danger
        onClick={() => handleLogOut()}
        icon={<FiLogOut />}
      >
        <span style={{ marginLeft: "5px" }}>LogOut</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <LHeader>
      <BannerTitle>{title}</BannerTitle>
      <div style={{ display: "flex", alignItems: "center" }}>
        {userInfo?.profile_pic ? (
          <UserProfileImage
            src={`data:image/png;base64,${userInfo?.profile_pic}`}
          />
        ) : (
          ""
        )}
        <div>
          <UserName>
            {userInfo ? userInfo?.firstname : "loading..."}{" "}
            {userInfo && userInfo?.lastname}
          </UserName>
          <UserTitle>Participant Account</UserTitle>
        </div>
        <Dropdown overlay={menu}>
          <div
            style={{ marginLeft: "10px", color: `${grey1}`, cursor: "pointer" }}
          >
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </LHeader>
  );
};
const MHeader = styled.div`
  height: 30px;
  width: 100%;
  font: 15px;
  padding: 5px 0 0 0;
  color: ${({ changeColor }) => (changeColor ? white : black)};
  font-family: ${fontFamily.inter};
  font-weight: bolder;
  text-align: center;
  top: 0;
  ${"" /* position: fixed; */}
  background: ${({ changeColor }) => (changeColor ? lightgreen1 : white)};
  @media ${device.laptop} {
    display: none;
  }
`;

const LHeader = styled.div`
  display: none;
  @media ${device.laptop} {
    height: 80px;
    background: ${white};
    margin-bottom: 50px;
    ${"" /* border: 0px 3px 8px -1px rgba(50, 50, 71, 0.09); */}
    border:3px 3px 8px -1px rgba(50, 50, 71, 0.08);
    width: 100%;
    text-align: center;
    padding: 1.3vw 4.5vw 1.3vw 2.6vw;
    display: flex;
    justify-content: space-between;
    align-item: center;
    font-color: ${black};
    font-family: ${fontFamily.Sora};
  }
`;

const BannerTitle = styled.h2`
  font-size: 1.7vw;
  font-weight: bold;
`;
const UserName = styled.h5`
  text-align: start;
  font-family: ${fontFamily.Sora};
  color: ${grey1};
  font-size: 0.91vw;
  ${"" /* line-height:; */}
  font-weight: bold;
`;
const UserTitle = styled.h4`
  text-align: start;
  font-family: ${fontFamily.Sora};
  color: ${lightgreen2};
  font-style: italic;
  line-height: 0.065vw;
  font-size: 0.65vw;
  margin-top: -0.32vw;
`;
