import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../globalComponent/Index";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  setInvisible,
  resendVerificationLink,
  clearNotification,
} from "../../redux-toolkit/resendVerificationSlice";
import {
  MessageNotificationSuccess,
  MessageNotificationErrorLong,
} from "../../globalComponent/Index";
/* import Alert from '@material-ui/lab/Alert'; */
import { makeStyles } from "@material-ui/core/styles";
import { UseLoginMail } from "./context";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const AlertDialog = (props) => {
  const history = useHistory();
  const { email, setEmail } = UseLoginMail();
  const classes = useStyles();
  const dispatcher = useDispatch();
  const visible = useSelector((state) => state.resendVerification.visible);
  const notification = useSelector(
    (state) => state.resendVerification.notification
  );
  const data = useSelector((state) => state.resendVerification.data);
  // console.log("resend data", data);

  React.useEffect(() => {
    if (notification === "success") {
      MessageNotificationSuccess("Verification Link sent");
      dispatcher(setInvisible());
      dispatcher(clearNotification());
    }
    if (notification === "error") {
      MessageNotificationErrorLong("Verification Link Not Sent");
      dispatcher(setInvisible());
      dispatcher(clearNotification());
    }
  }, [notification]);
  return (
    <Dialog
      open={visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      isableEscapeKeyDown={true}
    >
      <DialogTitle id="alert-dialog-title">Verification Mail</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={classes.root}>
            <p>
              A verification email has been sent to <strong>{email}</strong>.
              Kindly verify your email and login to complete profile, thank you.
            </p>
            <h4
              style={{ cursor: "pointer", color: "blue", textAlign: "center" }}
              onClick={() => {
                dispatcher(setInvisible());
                history.push("/");
              }}
            >
              Go to login
            </h4>
            <div style={{ textAlign: "center" }}>
              {notification === "loading" ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => dispatcher(resendVerificationLink(email))}
                  style={{
                    border: "1px solid grey",
                    color: "blue",
                    cursor: "pointer",
                    margin: "10 50%",
                    fontSize: "12px",
                    background: "none",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Resend verification link
                </button>
              )}
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Continue
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
