import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";
import { darkgreen, lightgreen } from "../globalAssets/theme";
import { device } from "../globalAssets/mediaQueries";

export const LoggerButton = ({ htmlType, size, text, onClick, full }) => {
  return (
    <Button size={size} onClick={onClick} htmlType={htmlType} full={full}>
      {text}
    </Button>
  );
};

const Button = styled(AntButton)`
  width: 100%;
  &.ant-btn,
  .ant-btn:hover,
  .ant-btn:focus {
    border: none;
    background: linear-gradient(to right, ${darkgreen} 0%, ${lightgreen} 100%);
    margin: 10px auto 0 auto;
    color: white;
    font-weight: 600;
    font-size: 13px;
    ${"" /* font-family */}
    text-align: center;
    border-radius: 5px;
    @media ${device.laptop} {
      width: 100%;
     // width: ${props => props.full? '100%' : '40%'};
    }
  }
`;
