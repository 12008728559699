import React from 'react';
import Logo from "../assets/surveypluslogo.svg";
import styled from "styled-components";
import { device } from "../globalAssets/mediaQueries";

export const BigLogo = () => {
  return <BLogo src={Logo} />;
};

export const SmallLogo = () => {
  return <SLogo src={Logo} />;
};

const BLogo = styled.img`
  height: 30%;
  width: 170px;
  margin: 15px 0;
`;
const SLogo = styled.img`
  ${"" /* @media ${device.laptop} { */}
  height: 5vw;
  width: 10vw;
  text-align: center;
  padding-left: 0.5vw;
  ${"" /* text-align: center; */}
  margin: 0.65vw auto;
  ${"" /* } */}
`;
