import React from "react";
import { Row, DatePicker, Col, Button as AntButton, Radio } from "antd";
import moment from "moment";
import styled from "styled-components";
import { device } from "../../globalAssets/mediaQueries";
import { darkorange, lightorange } from "../../globalAssets/theme";
import {
  Form,
  DropDown,
  FormItem,
  LoggerButton,
  RadioButton,
  GroupRadio,
} from "../../globalComponent/Index";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../../redux-toolkit/registerSlice";
import { useStateLga } from "../../helperFunctions/useStatesLgas";

export const UpdateLocationComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  const [states, lgas, setStateLgas] = useStateLga();

  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        label="State"
        name="state"
        rules={[
          {
            required: true,
            message: "Please select a state",
          },
        ]}
      >
        <DropDown
          values={states}
          onChange={(event) => setStateLgas(event.currentTarget.value)}
        />
      </FormItem>
      <FormItem
        label="L.G.A"
        name="lga"
        rules={[
          {
            required: true,
            message: "Please select a L.G.A",
          },
        ]}
      >
        <DropDown values={lgas} />
      </FormItem>
      <Row>
        <Col xs={24}>
      <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateSettlementComponent = (props) => {
  const dispatcher = useDispatch();
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="settlement"
        rules={[
          {
            required: true,
            message: "Please select a settlment",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Rural" />
          <RadioButton text="Urban" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateMaritalStatusComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="marrital_status"
        rules={[
          {
            required: true,
            message: "Please select a your marital status",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Married (Monogamy)" />
          <RadioButton text="Married (Polygamy)" />
          <RadioButton text="Co-habiting/Living together" />
          <RadioButton text="Divorced" />
          <RadioButton text="Seperated" />
          <RadioButton text="Widowed" />
          <RadioButton text="Never married" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateHouseHoldComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="household"
        rules={[
          {
            required: true,
            message: "Please select an household value",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="You are the head of the household" />
          <RadioButton text="Spouse/partner" />
          <RadioButton text="Parent/parent-in-law" />
          <RadioButton text="Grandparent (or spouse's grandparent)" />
          <RadioButton text="Child/adopted child/stepchild" />
          <RadioButton text="Son/daughter-in-law" />
          <RadioButton text="Other relative" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateGenderComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="gender"
        rules={[
          {
            required: true,
            message: "Please select your gender",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Male" />
          <RadioButton text="Female" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateDOBComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  const dateFormat = "MM/DD/YYYY";
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="date_of_birth"
        rules={[
          {
            required: true,
            message: "Please select your date of birth",
          },
        ]}
      >
        <DatePicker
          defaultValue={moment("01/01/2015", dateFormat)}
          format={dateFormat}
        />
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};
export const UpdateEducationLevelComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="education"
        rules={[
          {
            required: true,
            message: "Please select your Educational Level",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Pre-school" />
          <RadioButton text="Primary incomplete" />
          <RadioButton text="Primary complete" />
          <RadioButton text="Secondary incomplete" />
          <RadioButton text="Secondary complete" />
          <RadioButton text="University/Polytechnic Undergraduate" />
          <RadioButton text="University/Polytechnic OND complete" />
          <RadioButton text="Post-university incomplete" />
          <RadioButton text="Post-university complete" />
          <RadioButton text="Non-formal education (e.g. Arabic/Quranic education)" />
          <RadioButton text="No education" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateIncomeSourceComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="income"
        rules={[
          {
            required: true,
            message: "Please select your Income Source",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Salary/wages from Government" />
          <RadioButton text="Salary/wages from business/company (formal sector)" />
          <RadioButton text="Salary/wages from individuals (informal sector)" />
          <RadioButton text="Subsistence/small scale farmer" />
          <RadioButton text="Commercial/large scale farmer" />
          <RadioButton text="Own business/trader – non-farming" />
          <RadioButton text="Own business/trader – farming" />
          <RadioButton text="Own business – provide a service (e.g. hairdresser, tailor, mechanic)" />
          <RadioButton text="Rent/pension/returns on investment" />
          <RadioButton text="Get money/supplies from family/friends" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateMobileOwnershipComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="mobile_ownership"
        rules={[
          {
            required: true,
            message: "Please select your Mobile Ownership",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Does not own mobile phone" />
          <RadioButton text="Own basic phone" />
          <RadioButton text="Own smartphone" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

export const UpdateMonthlyIncomeComponent = (props) => {
  const onFinish = (values) => {
    // console.log("success:", values);
    props.onClick(values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <FormItem
        name="montly_income"
        rules={[
          {
            required: true,
            message: "Please select your Monthly Income",
          },
        ]}
      >
        <Radio.Group>
          <RadioButton text="Not currently working, someone else pays my bills" />
          <RadioButton text="Earn less than N30,000 monthly" />
          <RadioButton text="Earn between N30,001 and N100,000 monthly" />
          <RadioButton text="Earn above N100,000 monthly" />
        </Radio.Group>
      </FormItem>
      <Row gutter={[8]}>
        <Col xs={12}>
          <Button onClick={props.backFunction}>{props.backText}</Button>
        </Col>
        <Col xs={12}>
          <LoggerButton htmlType="submit" text={props.buttonText} />
        </Col>
      </Row>
    </Form>
  );
};

const Button = styled(AntButton)`
  width: 100%;
  &.ant-btn,
  .ant-btn:hover,
  .ant-btn:focus {
    border: none;
    background: linear-gradient(
      to right,
      ${darkorange} 0%,
      ${lightorange} 100%
    );
    margin: 10px auto 0 auto;
    color: white;
    font-weight: 600;
    font-size: 12px;
    ${"" /* text-align: center; */}
    ${"" /* width: 90%; */}
    border-radius: 5px;
    @media ${device.laptop} {
      ${"" /* width: 100%; */}
    }
  }
`;
