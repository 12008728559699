import styled from "styled-components";
import { Statistic } from "antd";
import { fontFamily } from "../../globalAssets/fontFamily";
import {
  lightgreen2,
  lightgreen,
  black,
  darkgreen,
  darkorange,
  lightorange,
  activeShadow,
  grey2,
  grey1,
  white,
  activeShadow3,
  activeShadow2,
} from "../../globalAssets/theme";
import { grey } from "@material-ui/core/colors";
import { device } from "../../globalAssets/mediaQueries";

export const DivContainer = styled.div`
  width: 100%;
  display: flex;
  ${"" /* margin: 20px 0; */}
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RedeemableDiv = styled.div`
  width: 170px;
  height: 55px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, ${darkgreen} 0%, ${lightgreen} 100%);
  border-radius: 3px;
  box-shadow: -1px 14px 10px -10px ${activeShadow3};
`;
export const LifetimeDiv = styled.div`
  width: 170px;
  height: 55px;
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: -1px 14px 10px -10px ${activeShadow3};
  background: linear-gradient(to right, ${darkorange} 0%, ${lightorange} 100%);
`;
export const DivTitle = styled.h3`
  font-family: ${fontFamily.Sora};
  font-size: 13px;
  color: ${black};
  font-weight: bold;
  margin-top: 20px;
  line-height: 10px;
`;
export const TextSmall = styled.h5`
  text-align: center;
  color: ${grey2};
  font-family: ${fontFamily.Sora};
  font-size: 8px;
  margin-bottom: 25px;
`;
export const TextSmallDiv = styled.div`
  text-align: center;
  margin-top: 35px;
  font-size: 10px;
  color: ${grey2};
  font-style: italic;
  border: 1px dashed ${activeShadow3};
  @media ${device.laptop} {
    width: 30vw;
    margin: 35px auto;
  }
`;

export const StatVal = styled(Statistic)`
  font-size: 20px !important;
  & .ant-statistic-content {
    font-size: 18px;
  }
  & .ant-statistic-content-value-int {
    color: ${white} !important;
    line-height: 0px;
    font-weight: bold;
    font-family: ${fontFamily.Sora};
  }
  & .ant-statistic-content-prefix {
    color: ${white} !important;
    font-family: ${fontFamily.Sora};
    font-weight: bold;
  }
`;
