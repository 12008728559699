import React from "react";
import styled from "styled-components";
import { activeShadow, darkgreen } from "../globalAssets/theme";
import { Radio } from "antd";
import { fontFamily } from "../globalAssets/fontFamily";

export const GroupRadio = ({ children }) => {
  return <Radio.Group style={{ textAlign: "start" }}>{children}</Radio.Group>;
};

export const RadioButton = (props) => {
  const radioStyle = {
    textAlign: "left",
    display: "block",

    // height: "30px",
    // lineHeight: "30px",
  };
  return (
    <StyledRadio style={radioStyle} value={props.text}>
      {props.text}
    </StyledRadio>
  );
};
const StyledRadio = styled(Radio)`
  color: ${darkgreen};
`;
