import React from "react";
import styled from "styled-components";
import { fontFamily } from "../globalAssets/fontFamily";

export const LTitle = ({ title }) => {
  return <LoginTitle>{title}</LoginTitle>;
};

const LoginTitle = styled.h2`
  font-weight: bold;
  font-size: 21px;
  margin: 10px 0 20px 0;
  ${"" /* font-size: 20px; */}
  font-family: ${fontFamily.Sora};
`;
