import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";

const initialState = {
  error: "",
  notification: "",
  loading: "",
  message: "",
};

export const updateImage = createAsyncThunk(
  "updateProfile/image",
  async (payload, { rejectWithValue }) => {
    try {
      const updateImage = await SideEffect.updateProfilePic(payload);
      console.log("the payload", payload);
      console.log(updateImage);
      return updateImage;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const updateImageSlice = createSlice({
  name: "updateProfile",
  initialState,
  reducers: {
    clearNotification: (state) => {
      state.notification = "";
    },
  },
  extraReducers: {
    [updateImage.fulfilled]: (state, action) => {
      state.notification = "success";
      state.message = action.payload;
    },
    [updateImage.pending]: (state, { payload }) => {
      state.notification = "loading";
    },
    [updateImage.rejected]: (state, { error }) => {
      state.notification = "error";
      state.error = error;
    },
  },
});

export default updateImageSlice.reducer;
export const { clearNotification } = updateImageSlice.actions;
