import styled from "styled-components";
import { grey1, grey2, lightgreen } from "../../globalAssets/theme";
import { fontFamily } from "../../globalAssets/fontFamily";

export const HeroContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  padding: 50px 10px 10px 10px;
  text-align: center;
  margin: 0 auto;
  background: "white";
  position: relative;
`;

export const Forgot = styled.h5`
  line-height: 1px;
  ${"" /* margin: -15px 0; */}
  text-align:right;
  font-size: 10px;
  color: ${grey2};
  cursor: pointer;
  font-family: ${fontFamily.inter};
`;

export const Text1 = styled.h5`
  color: ${grey1};
  margin: 10px 0;
  font-size: 12px;
  test-align: center;
  font-family: ${fontFamily.inter};
  font-weight: 600;
`;
export const Text2 = styled.span`
  font-size: 11px;
  test-align: center;
  color: ${lightgreen};
`;
