import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* import Alert from '@material-ui/lab/Alert'; */
import { makeStyles } from "@material-ui/core/styles";
import { fetchUserInfo } from "../../redux-toolkit/fetchUserInfoSlice";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const AlertDialog = (props) => {
  const classes = useStyles();
  const dispatcher = useDispatch();

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      isableEscapeKeyDown={true}
    >
      <DialogTitle id="alert-dialog-title">Survey Completed</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={classes.root}>
            <p severity="success">
              Thank you for taking the survey, points have been added to your
              wallet
            </p>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleClose();
            dispatcher(fetchUserInfo());
          }}
          color="primary"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
