import React from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  CompoenentContainer,
  IBackArrowBlack,
  MobileHeader,
  LaptopHeader,
  SurveyCard,
  EmptyData,
} from "../../globalComponent/Index";
import { Header } from "./Style";

const Survey = () => {
  const surveyList = useSelector((state) => state.surveyList.surveyList);

  const surveyCardArray = surveyList ? (
    surveyList.map((survey, id) => (
      <Col xs={24} sm={12} md={8} key={id}>
        <SurveyCard
          key={id}
          questionNumber={survey.num_questions}
          questionTitle={survey.survey_name}
          id={survey.id}
        />
      </Col>
    ))
  ) : (
    <EmptyData />
  );
  return (
    <CompoenentContainer>
      <IBackArrowBlack />
      <MobileHeader title="Surveys" />

      <Header>All Survey</Header>
      <LaptopHeader title="Surveys" />
      <Row>
        {/* {surveyList ? ( */}
        {surveyCardArray}
        {/* ) : ( */}
        {/* <h1>Surveys will be assigned to you shortly</h1> */}
        {/* )} */}
      </Row>
    </CompoenentContainer>
  );
};

export default Survey;
