import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { device } from "../../globalAssets/mediaQueries";
import { HeroContainer } from "../../globalComponent/Container";
import { LaptopHeader, MobileHeader } from "../../globalComponent/Header";
import { CompoenentContainer } from "../../globalComponent/HeroContainer";
import { IBackArrow } from "../../globalComponent/IBack";
import { Table } from "../../globalComponent/Index";
import { Date, SurveyContainer, SurveyTitle, SurveyNumber } from "./Style";
import { fetchSurveyHistoryAction } from "../../redux-toolkit/fetchSurveyHistory";
const color = true;

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Title",
    dataIndex: "survey_name",
    key: "survey_name",
  },
  {
    title: "Survey Points",
    dataIndex: "points",
    key: "points",
  },
];
const SurveyHistory = () => {
  const dispatcher = useDispatch();
  const surveyHistory = useSelector(
    (state) => state.surveyHistory.surveyHistory
  );
  // console.log("the history is", surveyHistory);

  React.useEffect(() => {
    dispatcher(fetchSurveyHistoryAction(1));
  }, []);

  const history = surveyHistory.map((survey) => (
    <CardContainer>
      <Date>{survey.date}</Date>
      <SurveyContainer>
        <SurveyTitle>{survey.survey_name}</SurveyTitle>
        <SurveyNumber>{survey.points}</SurveyNumber>
      </SurveyContainer>
    </CardContainer>
  ));
  return (
    <CompoenentContainer>
      <IBackArrow />
      <MobileHeader changeColor={color} title="Survey History" />
      <LaptopHeader title="Survey History" />
      <HeroContainer style={{ padding: "20px 10px", marginBottom: "15px" }}>
        {history} <Table dataSource={surveyHistory} columns={columns} />
      </HeroContainer>
    </CompoenentContainer>
  );
};

export default SurveyHistory;

const CardContainer = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`;
