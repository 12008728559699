import React from 'react';
import { FiLogOut } from "react-icons/fi";

import styled from "styled-components";
import { black } from "../globalAssets/theme";
export const ILogout = () => {
  return <StyledLogOut />;
};

const StyledLogOut = styled(FiLogOut)`
  font-size: 20px;
  ${"" /* padding: 2px; */}
  color: ${black};
`;

export const LogoutIcon = () => {
  return <StyleLogout />;
};
const StyleLogout = styled(FiLogOut)`
  font-size: 1.1vw;
  margin: 0 10px 0 0;
`;
