import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";
import { ParticipantProtectedRoute } from "./components/participantProtectedRoute";
import Login from "./components/Login/Index";
import Register from "./components/Register/Index";
import { UserPolicy } from "./components/UserPolicy/index";
import "antd/dist/antd.css";
import Dashboard from "./components/Dashboard/Index";
import YourDashboard from "./components/YourDashboard/Index";
import MyWallet from "./components/Wallet/Index";
import Survey from "./components/Survey/Index";
import Profile from "./components/YourProfile/Index";
import SurveyHistory from "./components/SurveyHistory/Index";
import { loadProgressBar } from "axios-progress-bar";
import UpdateProfile from "./components/UpdateProfile/Index";
import { SurveyGround } from "./components/TakeSurvey/SurveyGround";
import { NavigateExternalSurveyLink } from "./components/NavigateExternalSurveyLink";
import {
  SurveyQuestionAnwserContext,
  ActiveSurveyIdContext,
  ActiveParticipantIdContext,
} from "./components/TakeSurvey/context";
import { LoginMailContext } from "./components/Login/context";
import "axios-progress-bar/dist/nprogress.css";
import TransactionBoard from "./components/Transactions";

const App = () => {
  loadProgressBar();
  const [email, setEmail] = useState("yo");

  return (
    <LoginMailContext.Provider value={{ email, setEmail }}>
      <Router>
        <Switch>
          <Route
            exact
            path={`/external-survey-link/:surveyId`}
            component={NavigateExternalSurveyLink}
          />
          <ParticipantProtectedRoute path="/dashboard" component={Dashboard} />
          <Route exact path="/register" component={Register} />
          <Route path="/privacy-policy">
            <UserPolicy />
          </Route>
          <Route exact path="/" component={Login} />
        </Switch>
      </Router>{" "}
    </LoginMailContext.Provider>
  );
};

export const DashboardRoute = () => {
  const { path, url } = useRouteMatch();
  // loadProgressBar()
  const [activeParticipantId, setActiveParticipantId] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [activeSurveyId, setActiveSurveyId] = useState(0);
  const [questionAnswer, setQuestionAnswer] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      console.log("USERINFOR", JSON.parse(localStorage.getItem("userInfo")));
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    }
  }, []);

  return (
    <ActiveParticipantIdContext.Provider
      value={{ activeParticipantId, setActiveParticipantId, userInfo }}
    >
      <ActiveSurveyIdContext.Provider
        value={{ activeSurveyId, setActiveSurveyId }}
      >
        <SurveyQuestionAnwserContext.Provider
          value={{ questionAnswer, setQuestionAnswer }}
        >
          <Switch>
            <Route exact path={path} component={YourDashboard} />
            <Route exact path={`${path}/wallet`} component={MyWallet} />
            <Route exact path={`${path}/survey`} component={Survey} />
            <Route exact path={`${path}/profile`} component={Profile} />
            <Route
              exact
              path={`${path}/transaction`}
              component={TransactionBoard}
            />

            <Route
              exact
              path={`${path}/surveyhistory`}
              component={SurveyHistory}
            />
            <Route
              exact
              path={`${path}/updateprofile`}
              component={UpdateProfile}
            />
            <Route
              exact
              path={`${path}/take-survey/:id`}
              component={SurveyGround}
            />
          </Switch>
        </SurveyQuestionAnwserContext.Provider>
      </ActiveSurveyIdContext.Provider>
    </ActiveParticipantIdContext.Provider>
  );
};

export default App;
