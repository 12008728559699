import React from 'react';
import { GiReceiveMoney } from "react-icons/gi";
import styled from "styled-components";
import { white } from "../globalAssets/theme";
export const RedeemIcon = () => {
  return <StyleRedeemIcon />;
};

const StyleRedeemIcon = styled(GiReceiveMoney)`
  font-size: 2vw;
  color: ${white};
  text-align: center;
`;
