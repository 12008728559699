import React from 'react';
import { IoCameraSharp } from "react-icons/io5";
import { darkorange } from "../globalAssets/theme";

import styled from "styled-components";

export const CameraIcon = () => {
  return <StyledCamera />;
};

const StyledCamera = styled(IoCameraSharp)`
  font-size: 26px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  color: white;
  border-radius: 50%;
  padding: 5px;
  background: ${darkorange};
`;
