import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { SideEffect } from "../api/sideEffects";
const initialState = {
  data: "",
  error: "",
  notification: "",
  visible: false,
};

export const resendVerificationLink = createAsyncThunk(
  "resendVerification/resendLink",
  async (payload, { rejectWithValue }) => {
    try {
      const resendLink = await SideEffect.resendVerificationLink(payload);
      console.log("the payload from resend verification link", resendLink);
      return resendLink;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);
const resendVerification = createSlice({
  name: "resendVerification",
  initialState,
  reducers: {
    clearNotification: (state) => {
      state.notification = "";
    },
    setVisible: (state) => {
      state.visible = true;
    },
    setInvisible: (state) => {
      state.visible = false;
    },
  },
  extraReducers: {
    [resendVerificationLink.fulfilled]: (state, { payload }) => {
      state.notification = "success";
      state.data = payload;
    },
    [resendVerificationLink.pending]: (state, { payload }) => {
      state.notification = "loading";
    },
    [resendVerificationLink.rejected]: (state, { error, payload }) => {
      state.notification = "error";
      // console.log("Error message", error);
    },
  },
});

export const {
  clearNotification,
  setVisible,
  setInvisible,
} = resendVerification.actions;

export default resendVerification.reducer;
