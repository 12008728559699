import React from "react";
import { Row, Col, Statistic } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "../../globalAssets/mediaQueries";
import {
  white,
  lightgreen,
  darkgreen,
  lightorange,
  darkorange,
  grey1,
  black,
} from "../../globalAssets/theme";
import { RedeemIcon, LifeTimeIcon } from "../../globalComponent/Index";
import { fontFamily } from "../../globalAssets/fontFamily";

export const AccountCard = () => {
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  return (
    <Container>
      <Row justify="center">
        <Col md={9}>
          <CardContainer>
            <RedeemableIconContainer>
              <RedeemIcon />
            </RedeemableIconContainer>{" "}
            <CardTitle>Wallet</CardTitle>
            <StatVal value={userInfo?.wallet} prefix="₦" />
          </CardContainer>
        </Col>
        <Col md={6}>
          <CardContainer>
            <LifeTimeIconContainer>
              <LifeTimeIcon />
            </LifeTimeIconContainer>
            <CardTitle>Lifetime Points</CardTitle>
            <StatVal value={userInfo?.points} />
          </CardContainer>
        </Col>
      </Row>
    </Container>
  );
};
const Container = styled.div`
  display: none;
  @media ${device.laptop} {
     display: block;
    background:none;
    width: 90%;
    margin: 2vw 0 5vw 2.7vw;
    height: 50px;
  }
`;

const CardContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    padding: 0.65vw;
    display: block;
    position: relative;
    background: ${white};
    box-shadow: 1px 0px 4px 0px rgba(50, 50, 71, 0.1);
    height: 100px;
    width: 300px;
    border-radius: 5px;
    text-align: right;
  }
`;
const RedeemableIconContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    top: -20px;
    left: 30px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    padding: 5px 0 0 0;
    text-align: center;
    position: absolute;
    background-image: linear-gradient(to right, ${darkgreen}, ${lightgreen});
  }
`;
const LifeTimeIconContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    top: -20px;
    left: 30px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    padding: 5px 0 0 0;
    text-align: center;
    position: absolute;
    background-image: linear-gradient(to right, ${darkorange}, ${lightorange});
  }
`;
const CardTitle = styled.h3`
  color: ${black};
  font-size: 13px;
  ${"" /* font-weight:bold; */}
  font-family: ${fontFamily.Sora};
`;

const StatVal = styled(Statistic)`
  font-size: 20px !important;
  & .ant-statistic-content {
    font-size: 25px;
    margin-top: -20px;
    font-weight: bold;
  }
  & .ant-statistic-content-value-int {
    text-align: right !important;
    color: ${black} !important;
    line-height: 1px;
  }
  & .ant-statistic-content-prefix {
    color: ${black} !important;
  }
`;
