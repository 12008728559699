import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SideEffect } from "../api/sideEffects";
export const submitScreeningResponseAction = createAsyncThunk(
  "submitScreeningResponse/screening",
  async (payload, { rejectWithValue }) => {
    try {
      const screeningResult = await SideEffect.submitScreeningResponse(payload);
      // console.log("screeningResult", screeningResult.data.message);
      //   return screeningResult.data;
      return screeningResult.data.message;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const submitScreeningResponse = createSlice({
  name: "submitScreeningResponse",
  initialState: {
    screeningResponseNotify: {
      status: "",
      result: false,
    },
  },
  reducers: {
    screeningResponseNotifyAction: (state, { payload }) => {
      state.screeningResponseNotify = payload;
    },
  },
  extraReducers: {
    [submitScreeningResponseAction.fulfilled]: (state, action) => {
      state.screeningResponseNotify.status = "success";
      state.screeningResponseNotify.result = action.payload;
    },
    // [submitScreeningResponseAction.pending]: (state, { payload }) => {
    //   state.screeningResponseNotify.status = "loading";
    // },
    [submitScreeningResponseAction.rejected]: (state, { error }) => {
      state.screeningResponseNotify.status = "error";
    },
  },
});

export const {
  screeningResponseNotifyAction,
} = submitScreeningResponse.actions;
export default submitScreeningResponse.reducer;
